import { createApp } from 'vue'
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import * as Sentry from "@sentry/vue";

import 'v-calendar/style.css';
import 'primeicons/primeicons.css'
import '@/assets/main.css'
import '@/assets/transitions.css'

import hOS from './hOS.vue'
import {router} from './helpers'
import { setupCalendar } from 'v-calendar';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import StyleClass from "primevue/styleclass";
import Aura from '@primevue/themes/aura';

// import as directive
import BadgeDirective from 'primevue/badgedirective';
import {definePreset, palette} from "@primevue/themes";

import setup_components from "./primevue-components.js";
import FocusTrap from "primevue/focustrap";

// Use calendar defaults (optional)

const app = createApp(hOS);

Sentry.init({
    app,
    // @ts-ignore
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
});


app.use(PrimeVue, {
    ripple: true,
    theme: {
        preset: definePreset(Aura, {
            semantic: {
                primary: palette('{blue}')
            }
        }),
        options: {
            darkModeSelector: '.dark',
        }
    },
});
app.config.errorHandler
app.use(ToastService);
app.use(ConfirmationService);
app.use(createPinia());
app.use(router)
app.use(setupCalendar, {})
setup_components(app)


app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);
app.directive('focustrap', FocusTrap);

app.mount('#app')
