<script setup lang="ts">

import {ref} from "vue";
import Comments from "@/components/checkin/Comments.vue";
import pulseBackend from "@/backend/pulse.backend.ts";

const {pulse} = defineProps(['pulse'])
const showComments = ref(false)

async function toggleComments() {
  if (!showComments.value) {
    pulse.comments = await pulseBackend.fetchComments(pulse.id)
  }
  showComments.value = !showComments.value;
}</script>

<template>
  <div>
    <div class="text-muted-color text-xs cursor-pointer items-group pb-1" v-if="pulse.commentCount"
         @click="toggleComments();">
      {{ pulse.commentCount }} comments
      <i class="pi ml-1 !text-xs"
         :class="{'pi-angle-down': !showComments, 'pi-angle-up': showComments}"
      />
    </div>
    <Comments :commentable="pulse" v-if="showComments" :hide-header="true" class="text-xs"/>
  </div>
</template>
