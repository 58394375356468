<script setup lang="ts">

import Popover from "primevue/popover";
import {nextTick, ref} from "vue";
import {wrapInQuote} from "@/helpers/utils.ts";
import pulseBackend from "@/backend/pulse.backend.ts";

const commentPopover = ref()
const pulseToComment = ref()
const commenting = ref(false)
const comment = ref();
const loading = ref(false)

function show(event: MouseEvent, pulse: any) {
  commentPopover.value.show(event);
  commenting.value = false
  pulseToComment.value = pulse;
}

function hide() {
  setTimeout(() => {
    commenting.value = false
    commentPopover.value.hide()
  }, 500)
}

const textAreaRef = ref()

function showCommenter() {
  commenting.value = true
  const selection = window.getSelection();
  if (selection) {
    comment.value = wrapInQuote(selection.toString()) + "\n";
  }
  nextTick(() => {
    textAreaRef.value.$el.focus()
  })
}

async function saveComment() {
  loading.value = true
  await pulseBackend.comment(pulseToComment.value.id, comment.value)
  commentPopover.value.hide()
  loading.value = false
  if (!pulseToComment.value.commentCount) {
    pulseToComment.value.commentCount = 0;
  }
  pulseToComment.value.commentCount++;
}

defineExpose({
  show, hide
})

function sendOnCmdEnter(e: KeyboardEvent) {
  if (e.key === 'Enter' && e.metaKey) {
    saveComment();
    e.preventDefault();
  }
}

</script>

<template>
  <Popover ref="commentPopover" pt:content:class="!p-0">
    <Button icon="pi pi-comment" @click="showCommenter" text severity="secondary" size="small" v-if="!commenting"/>
    <div class="flex flex-col p-2" v-else>
      <Textarea v-model="comment" rows="3" cols="30" autoResize ref="textAreaRef" @keydown="sendOnCmdEnter" />
      <Button label="Send" icon="pi pi-save" class="mt-2" @click="saveComment" :loading="loading"  v-tooltip.bottom="'Press Cmd+Enter to send'"/>
    </div>
  </Popover>
</template>
