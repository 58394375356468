<script>
import {backend, Toaster} from "@/helpers";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Fieldset from "primevue/fieldset";

export default {
  emits: ['refresh-users'],
  components: {Fieldset, Textarea, Button, DataTable, Column, InputText},
  data: () => ({
    newUserEmails: "",
    newUsers: [],
  }),
  computed: {
    toaster() {
      return new Toaster(this.$toast);
    }
  },
  methods: {
    async saveUsers() {
      try {
        let created = await backend.createUsers(this.newUsers);
        this.toaster.success(`Created ${created.count} users`);
        this.newUsers = [];
        this.newUserEmails = "";
        this.$emit('refresh-users');
      } catch (e) {
        this.errorMsg = e;
        this.toaster.error(e);
      }
    },
    parseNewUsers(event) {
      let emails = event.target.value;
      this.newUsers = [];
      try {
        emails.split(",").forEach(email => {
          email = email.trim();
          let username = email.split('@')[0].trim();
          let name = username.split(".").map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(" ");
          if (username || name || email)
            this.newUsers.push({username, name, email});
        })
      } catch (e) {
        this.newUsers.push({username: e})
      }
    },
    onCellEditComplete(e) {
      const { data, newValue, field } = e;
      if (newValue.trim().length > 0) data[field] = newValue;
    }

  }
}
</script>

<template>
  <Fieldset legend="Add users" toggleable collapsed>
    <div>
      <Textarea @input="parseNewUsers" placeholder="New User Emails (comma-separated)" v-model="newUserEmails" class="w-full"/>
      <DataTable :value="newUsers" editMode="cell" @cell-edit-complete="onCellEditComplete">
        <Column :header="`# (${newUsers.length})`">
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template>
        </Column>
        <Column field="name" header="Name">
          <template #editor="{data, field}">
            <InputText v-model="data[field]" autofocus />
          </template>
        </Column>
        <Column field="username" header="Username">
          <template #editor="{data, field}">
            <InputText v-model="data[field]" autofocus />
          </template>
        </Column>
        <Column field="email" header="E-mail">
          <template #editor="{data, field}">
            <InputText v-model="data[field]" autofocus />
          </template>
        </Column>
      </DataTable>

      <Button @click="saveUsers">Create</Button>
    </div>
  </Fieldset>


</template>