<script setup lang="ts">

import {computed, ref} from "vue";
import {profileBackend} from "@/backend/profile.backend.ts";
import Debug from "@/components/Debug.vue";
import CheckinCard from "@/components/CheckinCard.vue";
import HOSAvatar from "@/components/hOSAvatar.vue";
import Skeleton from "primevue/skeleton"
import {todayWeek} from "@/composables/useRetroWeek.ts";
import RetroWeekPicker from "@/components/retro/RetroWeekPicker.vue";

const {profile} = defineProps(['profile'])
const selectedWeek = ref(await todayWeek());
const checkins = ref(await fetchCheckins());
const loading = ref(false);
const isReviewer = computed(() => profile.relations.reviewer);

async function fetchCheckins() {
  const checkins = await profileBackend.getCheckins(profile.user.id, selectedWeek.value.toISODate() as string);
  checkins.forEach((c: any) => c.person = profile.user);
  return checkins
}

async function init() {
  loading.value = true;
  checkins.value = await fetchCheckins();
  loading.value = false;
}

</script>

<template>
  <div class="flex flex-col">

    <RetroWeekPicker v-model="selectedWeek" @change="init" class="self-start mb-2"/>
    <div v-if="!loading" class="flex flex-col gap-2">
      <div class="flex flex-col p-4 border rounded-md" v-for="checkin in checkins">
        <CheckinCard :key="checkin.id" :entry="checkin" :readonly="true">
          <template #left>
            <div class="items-group" v-if="isReviewer">
              <hOSAvatar :user="checkin.leader" size="medium" class="mr-2" v-if="checkin.leader"/>
              <span class="font-bold text-lg">{{ checkin.leader ? checkin.leader.name : 'Self' }}</span>
            </div>
          </template>
          <template #skip>
            <div class="items-group" v-if="isReviewer">
              <hOSAvatar :user="checkin.leader" size="medium" class="mr-2" v-if="checkin.leader"/>
              <span class="font-bold text-lg">{{ checkin.leader ? checkin.leader.name : 'Self' }}</span>
            </div>
          </template>
        </CheckinCard>
      </div>
      <div v-if="!checkins || checkins.length == 0" class="p-4"> No checkins for this week.</div>
    </div>
    <Skeleton v-else/>
  </div>
<Debug :data="checkins" :expanded="false"/>
</template>
