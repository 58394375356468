<script setup>

import Fieldset from "primevue/fieldset";
import {onMounted, ref} from "vue";
import {adminBackend} from "@/backend/admin.backend";
import PulseAdminConfig from "@/components/pulse/PulseAdminConfig.vue";
import AppPage from "@/components/AppPage.vue";
import Button from "primevue/button";
import Debug from "@/components/Debug.vue";
import useAppToast from "@/composables/useAppToast";

const config = ref();
const loading = ref(true);
const saving = ref(false);
const toast = useAppToast();

onMounted(async () => {
  config.value = await adminBackend.appConfig();
  loading.value = false;
})

const save = async () => {
  saving.value = true;
  await adminBackend.saveAppConfig(config.value);
  saving.value = false;
  toast.success("Saved!")
}
</script>

<template>
  <AppPage title="App Configuration" :loaded="!loading">
    <div class="flex flex-col gap-2">
      <Fieldset legend="Pulse" toggleable>
        <PulseAdminConfig :config="config"/>
      </Fieldset>
      <div class="flex justify-end">
        <Button class="btn btn-primary" @click="save" :loading="saving">Save</Button>
      </div>
      <Debug :data="config"/>
    </div>
  </AppPage>
</template>
