<script setup lang="ts">

import {ref} from "vue";

const hidden = ref(false)

function toggleSidebar() {
  if (!hidden.value) {
    setTimeout(() => {
      hidden.value = true
    }, 200)
  } else {
    hidden.value = false
  }


}
</script>

<template>
  <div >
    <i class="pi pi-chevron-left shadow rounded-full border p-2 bg-surface hover:bg-primary hover:text-surface-0 absolute top-6 "
       :class="{
      'transform rotate-180': hidden,
      '-right-8': hidden,
      '-right-3': !hidden,
      'hidden group-hover:inline': !hidden,
    }"
       @click="toggleSidebar"
       v-styleclass="{
                  selector: `#app-sidebar-content`,
                  enterFromClass: 'lg:hidden',
                  enterActiveClass: 'lg:animate-fadein',
                  leaveActiveClass: 'lg:animate-fadeout',
                  leaveToClass: 'lg:hidden'
                           }"/>
  </div>
</template>
