<script>
import {DateTime} from "luxon";
import Button from "primevue/button";
import Card from "primevue/card";
import Badge from "primevue/badge";
import {RouterLink} from "vue-router";


export default {
  computed: {
    DateTime() {
      return DateTime
    }
  },
  props: ['sks', 'readonly'],
  emits: ['edit', 'cancel', 'save'],
  components: {Card, Badge, Button, RouterLink},
}

</script>

<template>
  <Card class="w-80 items-center justify-between"
        :pt="{
      body: () => ({
        class: 'w-full h-full justify-between'
      })
      }">
    <template #title>
      <div class="flex items-center gap-2">
        {{ sks.name }}
        <Badge :value="sks.postsCount" severity="info" />
        <Button icon="pi pi-pencil" @click="$emit('edit', sks)" class="p-button-rounded p-button-text" v-if="!readonly"/>
      </div>
    </template>
    <template #subtitle>
      {{ DateTime.fromISO(sks.createdAt).toISODate() }}
    </template>
    <template #content>
      <div v-if="sks.confidant">
        Confidant: {{ sks.confidant?.name }}
      </div>
      <div v-if="readonly">
        User: {{ sks.user?.name }}
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between items-end gap-2">
        <RouterLink :to="`/sks-post/${sks.slug}`" class="w-full" target="_blank" v-if="!readonly">
          <Button label="Share" class="w-full" severity="success" />
        </RouterLink>
        <RouterLink :to="`/sks/${sks.slug}`" class="w-full">
          <Button label="View" class="w-full" severity="primary" />
        </RouterLink>
      </div>
    </template>
  </Card>
</template>