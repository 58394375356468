import Popover from "primevue/popover";
import Button from "primevue/button";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import {Calendar, DatePicker} from "v-calendar";
import Fieldset from "primevue/fieldset";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Debug from "@/components/Debug.vue";
import Message from "primevue/message";
import Select from "primevue/select";
import Image from "primevue/image";

export default function components(app) {
    app.component('Popover', Popover);
    app.component('Button', Button);
    app.component('Tag', Tag)
    app.component('InputText', InputText)
    app.component('Textarea', Textarea)
    app.component('DatePicker', DatePicker)
    app.component('Calendar', Calendar)
    app.component('Fieldset', Fieldset)
    app.component('Tabs', Tabs)
    app.component('TabList', TabList)
    app.component('Tab', Tab)
    app.component('TabPanels', TabPanels)
    app.component('TabPanel', TabPanel)
    app.component('Debug', Debug)
    app.component('Message', Message)
    app.component('Select', Select)
    app.component('Image', Image)
}
