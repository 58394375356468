<script setup lang="ts">
import anchorme from 'anchorme';
import {computed} from "vue";
const {text} = defineProps(['text'])
const linkClass = 'text-link'
const linkified = computed(() => {
  if (!text) {
    return ''
  }
  return anchorme({
    input: text,
    options: {
      attributes: {
        target: '_blank',
        rel: 'noopener',
        class: linkClass
      },
    },
    extensions: [
      {
        // Replace full links to jira issues.
        test: /https:\/\/hosinc.atlassian.net\/browse\/(HOS-\d+)/gi,
        transform: str => replaceJiraLink(str)
      },
      {
        // Replace jira IDs. The tool is rather stupid, checking for the first symbol to avoid false positives in full links
        test: /[^/]HOS-\d+/gi,
        transform: (str) => `${str[0]}${replaceJiraId(str.substring(1))}`,
      },
      {
        // For the case of a jira ID at the beginning of the text
        test: /^HOS-\d+/gi,
        transform: (str) => `${replaceJiraId(str)}`,
      },
    ]
  })
})

function replaceJiraLink(jiralink: string) {
  const jiraId = jiralink.substring(jiralink.lastIndexOf('/') + 1)
  return replaceJiraId(jiraId)
}

function replaceJiraId(str: string) {
  return `<a target='_blank' class='${linkClass}' href="https://hosinc.atlassian.net/browse/${str}">${str}</a>`
}
</script>

<template>
  <div class="whitespace-pre-wrap break-words" v-html="linkified">
  </div>
</template>
