<script setup lang="ts">

import HOSAvatar from "@/components/hOSAvatar.vue";
import SlackButton from "@/components/SlackButton.vue";
import {humanDateMMMddyyyy} from "@/helpers/date.helper";
import WorkingWIthMeViewer from "@/components/user/WorkingWIthMeViewer.vue";
import {computed} from "vue";
import {findPersonality, firstNameOrMe} from "../helpers/utils.ts";
import {useAuthStore} from "@/stores";
import PulseWeeklyView from "@/components/pulse/PulseWeeklyView.vue";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import TimeoffTab from "@/components/user/TimeoffTab.vue";
import {ProfileResponseType} from "@/backend/profile.backend.ts";
import SkeletonSuspense from "@/components/SkeletonSuspense.vue";
import CheckinsTab from "@/components/user/CheckinsTab.vue";

type ProfilePropsType = {
  profile: ProfileResponseType | undefined
}
const authStore = useAuthStore()
const props = defineProps<ProfilePropsType>()

const user = computed(() => props.profile?.user)
const relations = computed(() => props.profile?.relations)

const hasTraits = computed(() => {
  return ['passions', 'frustrations', 'features', 'bugs', 'motivators', 'demotivators'].some(trait => user.value?.meta[trait])
})

const pulseShown = computed(() => {
  return user.value?.pulse_enabled && authStore.canSeePulse
})

const workingWithName = computed(() => {
  return firstNameOrMe(user.value, authStore.user);
})

</script>

<template>
  <div class="grid gap-4 grid-cols-12" v-if="user" :key="user.id">
    <div class="flex w-[15rem] justify-center col-span-12 md:col-span-3">
      <h-o-s-avatar :user="user" class="!w-[10rem] !h-[10rem]" size="xlarge" :key="user.id"/>
    </div>

    <div class="flex flex-col gap-2 w-full col-span-12 md:col-span-9">
        <span class="items-group">
          <span class="font-bold text-4xl">{{ user.name }}</span>
          <SlackButton :person="user"/>
          <slot name="actions"/>
        </span>
      <p class="italic text-muted-color text-xl">{{ user.title }}<span
          v-if="user.department?.name">, {{ user.department?.name }}</span></p>
      <p class="italic text-muted-color" v-if="user.meta.startDate">Joined {{
          humanDateMMMddyyyy(user.meta.startDate)
        }}</p>
      <p v-if="user.meta.location"><i class="pi pi-map-marker"/> {{ user.meta.location }}</p>
      <p v-if="user.meta.goal" class="text-xl"><span
          class="font-medium">Goal to achieve: </span><span>{{ user.meta?.goal }}</span></p> <!-- If present -->
      <p v-if="user.meta.funfact" class="text-xl"><span class="font-medium">Fun Fact: </span><span>{{
          user.meta?.funfact
        }}</span></p> <!-- If present -->
      <p v-if="user.meta.mbti" class="text-xl"><span class="font-medium">MBTI: </span>
        <a class="text-link" :href="`https://www.16personalities.com/${user.meta?.mbti}`"
           target="_blank">{{ findPersonality(user.meta?.mbti) }}</a>
      </p>
    </div>

    <Tabs value="details" class="col-span-12" :lazy="true">
      <TabList>
        <Tab value="details">Working with {{ workingWithName }}</Tab>
        <Tab value="pulse" v-if="pulseShown">Pulse</Tab>
        <Tab value="timeoff">Time Off</Tab>
        <Tab value="checkins" v-if="relations?.leader || relations?.reviewer">Checkins</Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="details">
          <WorkingWIthMeViewer :user="user" v-if="hasTraits"/>
        </TabPanel>
        <TabPanel value="pulse">
          <PulseWeeklyView :user-id="user.id"/>
        </TabPanel>
        <TabPanel value="timeoff">
          <SkeletonSuspense>
            <TimeoffTab :profile="profile" />
          </SkeletonSuspense>
        </TabPanel>
        <TabPanel value="checkins">
          <SkeletonSuspense>
            <CheckinsTab :profile="profile" />
          </SkeletonSuspense>

        </TabPanel>
      </TabPanels>
    </Tabs>


  </div>
</template>
