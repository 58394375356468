<script setup>
import {computed} from "vue";
import {DateTime} from "luxon";
import Select from "primevue/select";

const {user} = defineProps(['user'])
const emit = defineEmits(['update:user'])

const tz = computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone)

const timeOptions = computed(() => {
  let time = DateTime.now().startOf('day')
  const options = []
  for (let i = 0; i < 48; i++) {
    const localTime = time.toLocaleString(DateTime.TIME_SIMPLE);
    const utcTime = time.toUTC().toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    options.push({label: localTime, value: utcTime})
    time = time.plus({minutes: 30})
  }

  return options;
})
</script>

<template>
  <div>
    <Fieldset legend="Pulse" v-if="user.pulse_enabled">
      <div class="flex flex-row gap-2 items-center">
        <label>Send Pulse reminders at</label>
        <Select v-model="user.pulseReminder" :options="timeOptions" optionLabel="label" optionValue="value"
                @change="emit('update:user')" :showClear="true"/>
        <span>{{ tz }} time</span>
      </div>
      <div class="text-muted-color">
        <span v-if="!user.pulseReminder">You will not be prompted for pulse in Slack</span>
        <span v-else>Prompts are sent every day unless you're out of office</span>
      </div>
    </Fieldset>
  </div>
</template>
