<script setup lang="ts">

import TimeOffList from "@/components/timeoff/TimeOffList.vue";
import TimeoffApprover from "@/components/timeoff/TimeoffApprover.vue";
import {useAuthStore} from "@/stores";
import {computed, ref} from "vue";
import {timeoffBackend, TimeoffDTO} from "@/backend/timeoff.backend.ts";

const all = ref<TimeoffDTO[] | null>(null);

const isAdmin = computed(() => {
  return useAuthStore().isAdmin
})

const loadAll = async () => {
  all.value = await timeoffBackend.getAllTimeoff();
};


</script>

<template>
  <TimeOffList :timeoffList="all" :hide-comment="!isAdmin" :exportable="isAdmin" @mounted="loadAll">
    <template #timeoffActions="slotProps" v-if="isAdmin">
      <TimeoffApprover :timeoff="slotProps.data" @refresh="loadAll"/>
    </template>
  </TimeOffList>
</template>
