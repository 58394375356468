<script setup lang="ts">

import {DateTime} from "luxon";
import WeekPicker from "@/components/WeekPicker.vue";
import {onMounted, ref} from "vue";
import {todayWeek} from "@/composables/useRetroWeek";

const weekStart = defineModel<DateTime>({required: true})
const emit = defineEmits(['change'])

const todayWeekValue = ref();

onMounted(async () => {
  todayWeekValue.value = await todayWeek();
  if (!weekStart.value) {
    weekStart.value = todayWeekValue.value;
    emit('change')
  }

})

</script>

<template>
  <WeekPicker v-model="weekStart" @change="emit('change')" :today-week="todayWeekValue" />
</template>
