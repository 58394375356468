import {ref} from "vue";

console.log("Reading version")

const version = ref()
const isCurrentVersion = ref(true);

async function initVersion() {
    version.value = await fetchVersion();
    console.log("Initializing version", version.value)
}
async function fetchVersion() {
    const response = await fetch("/version?t=" + Date.now())
    return (await response.text()).trim();
}

// This one should run on ever route change (e.g. default layout)
async function checkVersion() {
    const recentVersion = await fetchVersion();
    isCurrentVersion.value = recentVersion === version.value;
    if (!isCurrentVersion.value) {
        console.log(`New version detected. Base: ${version.value}, Recent: ${recentVersion}`)
    }
}

export default function useVersion() {
    return {
        isCurrentVersion,
        checkVersion,
        initVersion
    }
}
