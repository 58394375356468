<script setup>
import {timeoffHelper as to} from "@/helpers/timeoff.helper";
import {computed} from "vue";

const {value, color, label} = defineProps(['value', 'color', 'label', 'hideLabel'])

const colorValue = computed(() => color || to.byValue(value).color)
const labelValue = computed(() => label || to.byValue(value).label)
</script>

<template>
  <div class="flex gap-2 items-center">
    <i class="pi pi-circle-fill" :style="{color: colorValue}"
       v-tooltip.top="hideLabel? labelValue : '' "/>
    <span v-if="!hideLabel">{{ labelValue }}</span>
  </div>
</template>
