<script>

import {backend, Toaster} from "@/helpers";
import AppPage from "@/components/AppPage.vue";
import Accordion from "primevue/accordion";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";
import AccordionPanel from "primevue/accordionpanel";

export default {
  components: {
    AppPage,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    Textarea,
    Button,
    FloatLabel,
    InputText
  },
  data: () => ({
    sksPost: {},
    firstName: '',
    lastName: '',
    fields: ['stop', 'keep', 'start'],
    errorMsg: null,
    saved: false,
    loaded: false
  }),
  async created() {
    const {first, last} = await backend.getNameForSKS(this.$route.params.slug)()
    this.firstName = first;
    this.lastName = last;
    this.loaded = true;
  },
  computed: {
    canSend() {
      const post = this.sksPost;
      return post.start || post.stop || post.keep || post.additional;
    },
    title() {
      if (this.saved)
        return "Thank you for your submission!";
      if (this.firstName)
        return `${this.firstName} ${this.lastName} is asking for your feedback`
      return "Loading...";
    },
    toaster() {
      return new Toaster(this.$toast);
    }
  },
  methods: {
    async sendSKSFeedback() {
      try {
        await backend.sendSKSPost(this.$route.params.slug)(this.sksPost);
        this.saved = true;
      } catch (e) {
        this.toaster.error("Oops, something went wrong");
        console.log(e);
      }
    },
  }
}
</script>
<template>
  <AppPage :title="title" :loaded="loaded">
    <div v-if="!saved" class="flex flex-col gap-2">
      <div class="p-message p-message-info p-component">
        <div class="p-message-wrapper">

          In the spirit of #AlwaysBeUpgradingYourself we've created this tool so that anyone within hOS
          may reach out to their friends, family and colleagues to collect vital feedback.
          The purpose of this is to work on any blind spots, improve in areas of opportunity and become the best version
          of yourself in and out of hOS!
        </div>
      </div>
      <div class="p-message p-message-warn p-component">
        <div class="p-message-wrapper">
          * Submissions made to this form are anonymous unless you choose to provide your name below.
        </div>
      </div>

      <Accordion multiple>
        <AccordionPanel v-for="(field, i) in fields" :value="`${i}`">
          <AccordionHeader>{{ field.toUpperCase() }}: What are things {{ firstName }} can {{ field }} doing?
          </AccordionHeader>
          <AccordionContent>
            <Textarea rows="5" class="w-full" v-model="sksPost[field]"/>
          </AccordionContent>
        </AccordionPanel>
        <AccordionPanel :value="`${fields.length}`">
          <AccordionHeader>Any general feedback or thoughts that you may have</AccordionHeader>
          <AccordionContent>
            <Textarea rows="5" v-model="sksPost.additional" class="w-full"/>
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <FloatLabel class="mt-8">
        <InputText v-model="sksPost.name" class="w-full" id="name"/>
        <label for="name">Would you be willing to meet 1x1? If so please add your name below.</label>
      </FloatLabel>
      <div>
        <Button label="Send" class="btn btn-primary" :disabled="!canSend" @click="sendSKSFeedback"/>
      </div>
    </div>
    <div v-else class="flex text-4xl place-self-center">
      <span>Thank you for your feedback!</span>
    </div>
  </AppPage>

</template>
