<script setup lang="ts">
import useAppUser from "@/composables/useAppUser.ts";
import hOSAvatar from "@/components/hOSAvatar.vue";
import {ref} from "vue";
import PulseUserConfig from "@/components/pulse/PulseUserConfig.vue";
import DatePicker from "primevue/datepicker";
import UserAdminDetails from "@/components/user/UserAdminDetails.vue";
import Skeleton from "primevue/skeleton";
import Select from "primevue/select";
import Debug from "@/components/Debug.vue";
import {peopleBackend} from "@/backend/people.backend";
import WorkingWithMeEditor from "@/components/user/WorkingWithMeEditor.vue";
import {profileBackend} from "@/backend/profile.backend";
import FileUpload from "primevue/fileupload";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import {firstNameOrMe} from "@/helpers/utils.ts";

const {user} = defineProps(['user'])
const emit = defineEmits(['update:user'])
const appUser = useAppUser();
const imageKey = ref(0)

const avatarUpload = ref();
const admin = appUser!.is_admin;

const departments = ref(await peopleBackend.departments())

async function uploadAvatar(event: any) {
  const file = event.files[0];
  await profileBackend.uploadAvatar(user.id, file);
  avatarUpload.value.clear()
  imageKey.value++
}

async function saveUser() {
  emit('update:user')
}


</script>

<template>

  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-4">
      <div class="absolute">
        <slot name="actions"/>
      </div>
      <div class="flex flex-col items-center">
        <hOSAvatar :user="user" class="mb-2" style="height: 10rem; width: 10rem" size="xlarge" :key="imageKey"/>
        <FileUpload mode="basic" accept="image/*"
                    :maxFileSize="1000000"
                    @select="uploadAvatar($event)"
                    ref="avatarUpload"
        />
      </div>
    </div>
    <div class="col-span-8">
      <div class="grid grid-cols-2 gap-2">
        <div>
          <label for="name" class="form-label">Name</label>
          <InputText v-model="user.name" id="name" class="w-full" @change="saveUser"/>
        </div>
        <div>
          <label for="title" class="form-label">Title</label>
          <InputText v-model="user.title" id="title" class="w-full" @change="saveUser" :disabled="!admin"/>
        </div>
        <div>
          <label for="location" class="form-label">Location</label>
          <InputText v-model="user.meta.location" id="location" class="w-full" @change="saveUser"/>
        </div>
        <div>
          <label for="department" class="form-label">Function</label>
          <Select v-model="user.departmentId" class="w-full"
                  :options="departments" optionLabel="name" optionValue="id"
                  @change="saveUser" :disabled="!admin" :showClear="true"/>

        </div>
        <div>
          <label for="since" class="form-label">Start date</label>
          <DatePicker v-model="user.meta.startDate" id="since" class="w-full" @update:modelValue="saveUser"
                      :disabled="!admin" dateFormat="M dd, yy"/>

        </div>
        <div>
          <label for="email" class="form-label">Email</label>
          <InputText v-model="user.email" id="email" class="w-full" @change="saveUser" :disabled="!admin"/>
        </div>
        <div></div>
        <div>
          <label for="username" class="form-label">Username</label>
          <InputText v-model="user.username" class="w-full" @change="saveUser" :disabled="!admin"/>
        </div>
      </div>

      <PulseUserConfig :user="user" @update:user="saveUser" class="col-span-2"/>
    </div>
    <Tabs class="col-span-12" value="working">
      <TabList>
        <Tab value="working">Working with {{firstNameOrMe(user, appUser)}}</Tab>
        <Tab value="admin" v-if="admin">Admin Area</Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="working">
          <WorkingWithMeEditor :user="user" :collapsed="admin" @update:user="saveUser"/>
        </TabPanel>
        <TabPanel value="admin">
          <Suspense>
            <UserAdminDetails :user="user" @update:user="saveUser" v-if="admin" class="col-span-12"/>
            <template #fallback>
              <Skeleton/>
            </template>
          </Suspense>

        </TabPanel>
      </TabPanels>
    </Tabs>

    <Debug :data="{departments}"/>
  </div>
</template>
