import {DateTime} from "luxon";

function getDate(dateStr) {
    return dateStr instanceof Date ?
        DateTime.fromJSDate(dateStr).startOf('day'):
        DateTime.fromISO(dateStr).startOf('day');
}

function getStart(timeoff) {
    return getDate(timeoff.start || timeoff.range.start);
}

function getEnd(timeoff) {
    return getDate(timeoff.end || timeoff.range.end);
}

export const timeoffHelper = {
    singleDay(timeoff) {
        return getStart(timeoff).hasSame(getEnd(timeoff), 'day');
    },

    format(date) {
        return date ? getDate(date).toFormat('MMM dd') : "";
    },

    findByDate(list, date) {
        const luxonDate = DateTime.fromJSDate(date);
        return list.find(to => {
            // Check if the clicked date is within the range of the attribute
            return luxonDate >= getStart(to) && luxonDate <= getEnd(to);
        });
    },

    countAllWorkdays(timeoffList, type = null) {
        const list = type ? timeoffList.filter(to => to.type == type) : timeoffList;
        return list.reduce((acc, to) => acc + this.countWorkdays(to), 0);
    },

    countWorkdays(timeoff) {
        let count = 0;
        let startDate = getStart(timeoff);
        const endDate = getEnd(timeoff);
        if (timeoff.partial) {
            return timeoff.partial / 8;
        }
        while (startDate <= endDate) {
            if (startDate.weekday < 6 && startDate.weekday >= 1) {
                count++;
            }
            startDate = startDate.plus({days: 1});
        }
        return count;
    },
    byValue(type) {
      for (const t of this.types()) {
        if (t.value == type) {
          return t;
        }
      }
      return null;
    },
    types() {
        return [
            {label: 'Vacation or Holiday Time', value: 'vacation', color: 'green'},
            {label: 'Personal Time', value: 'personal', color: 'blue'},
            {label: 'Sick Leave', value: 'sick', color: 'orange'},
            {label: 'Parental Leave (Paternity / Maternity)', value: 'parental', color: 'purple'},
            {label: 'Bereavement', value: 'bereavement', color: 'gray'}]
    },
}
