<script setup>

import Button from "primevue/button";
import Avatar from "primevue/avatar";
const LOGIN_URL = import.meta.env.VITE_API_URL + "/api/auth/google"

</script>

<template>
  <div class="flex flex-col gap-12">

    <Avatar image="/hos-logo-512x512.png" size="xlarge" shape="circle" class="!w-52 !h-52 mx-auto"/>
    <span class="text-4xl font-bold flex justify-center">Welcome to hOS People App!</span>
    <div class="flex justify-center">

      <a :href="LOGIN_URL">
        <Button label="Login with Google" icon="pi pi-google"/>
      </a>
    </div>
  </div>
</template>
