<script>
import '../../helpers/timeoff.helper'
import {timeoffHelper as to} from "@/helpers/timeoff.helper";
import Panel from "primevue/panel";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import {DatePicker} from "v-calendar";
import Select from "primevue/select";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";
import { isDark } from "@/composables/useDark";
import Debug from "@/components/Debug.vue";
import {DateTime} from "luxon";

export default {
  props: ['existing'],
  emits: ['save', 'close'],
  components: {Debug, Panel, Select, Button, InputText, Textarea, DatePicker, TimeoffType},
  data: () => ({
    timeOff: {
      range: {},
      type: 'vacation',
    },
    timeOffUtil: null,
    types: null,
    partialOptions: [{
      label: 'Full day (8hrs)',
      value: null
    }, {
      label: '6 hours',
      value: 6
    }, {
      label: 'Half day (4hrs)',
      value: 4
    }, {
      label: '2 hours',
      value: 2

    }]
  }),
  methods: {
    async saveTimeoff() {
      this.timeOff.start = DateTime.fromJSDate(this.timeOff.range.start).toISODate();
      this.timeOff.end = DateTime.fromJSDate(this.timeOff.range.end).toISODate();

      this.$emit('save', this.timeOff)
    },

    workingDaysText(timeoff) {
      const workdays = to.countWorkdays(timeoff);
      return workdays + ' working day' + (workdays == 1 ? '' : 's');
    },

    isDark() {
      return isDark()
    }
  },
  computed: {
    DateTime() {
      return DateTime
    },
    to() {
      return to
    },
    singleDay() {
      return to.singleDay(this.timeOff);
    },
    editingColor() {
      return to.byValue(this.timeOff.type).color;
    },
    saveable() {
      return this.timeOff.range.start && this.timeOff.range.end
    },
    selectedDates() {
      if (!this.timeOff.range.start)
        return "";
      let value = to.format(this.timeOff.range.start);

      if (!this.singleDay) {
        value += " - " + to.format(this.timeOff.range.end)
      }
      return value;
    },
  }
}


</script>
<template>
    <div class="flex gap-2 p-4">
      <div>
        <DatePicker v-model.range="timeOff.range" :color="editingColor"
                    :attributes="existing"
                    transparent
                    :is-dark="isDark()"

        />
      </div>
      <Panel :header="selectedDates || '<- Pick dates'">
      <div class="flex flex-col justify-around gap-2">
        <Select v-model="timeOff.type" :options="to.types()" option-value="value" class="w-full">
          <template #value="{value}">
            <TimeoffType :value="value" :key="value"/>
          </template>
          <template #option="{option}">
            <TimeoffType :color="option.color" :label="option.label"/>
          </template>
        </Select>
        <Select v-if="singleDay" v-model="timeOff.partial" :options="partialOptions"
                  option-value="value"
                  option-label="label" placeholder="Full day (8hrs)" class="w-full"/>
        <InputText v-else disabled :value="workingDaysText(timeOff)" class="w-full"/>
        <Textarea v-model="timeOff.comment" placeholder="leave a comment" class="w-full"/>
        <div >
          <div class="flex justify-between gap-2">

            <Button @click="saveTimeoff" :disabled="!saveable"> Save</Button>
            <Button @click="$emit('close')" severity="secondary" outlined>Cancel</Button>
          </div>

        </div>
      </div>
      </Panel>
    </div>
  <Debug :data="timeOff"/>


</template>
