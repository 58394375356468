<script setup lang="ts">
import {computed, ref} from "vue";
import {humanDateTime} from "@/helpers/date.helper";

type Commentable = {
  comments: any[];
}
const {commentable} = defineProps<{commentable: Commentable, hideHeader?: boolean}>()
const sortedComments = computed(() => {
  if (!commentable?.comments) return [];
  return commentable.comments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
});
const emit = defineEmits(['refresh'])
const refreshing = ref(false);
const count = computed(() => {
  if (!commentable?.comments?.length) return 'No comments';
  if (commentable.comments.length === 1) return '1 comment';
  return `${commentable.comments.length} comments`;
});

function refreshComments() {
  refreshing.value = true;
  emit('refresh');
  setTimeout(() => {
    refreshing.value = false;
  }, 500);
}
</script>

<template>
  <div class="flex flex-col">

    <div class="flex self-start text-muted-color cursor-pointer mb-1" @click="refreshComments" v-if="!hideHeader">
      <i class="pi pi-refresh mr-2 text-xs" :class="{'pi-spin': refreshing}"/>
      <span class=" text-xs">{{ count }}</span>
    </div>
    <div v-for="comment in sortedComments" :key="comment.id" class="flex flex-col p-2 border shadow rounded-md mb-1">
      <div class="flex justify-between text-xs text-muted-color">
        <span class="">{{ comment.user.name }}</span>
        <span> {{ humanDateTime(comment.createdAt) }}</span>
      </div>
      <span class="whitespace-pre-wrap">{{comment.comment}}</span>
    </div>
  </div>
</template>

