<script setup>
import {useAuthStore} from '@/stores';

const authStore = useAuthStore();
import AppPage from "@/components/AppPage.vue";
import WallOfFame from "@/components/WallOfFame.vue";


</script>
<template>
  <AppPage title="Wall of Fame" :loaded="true">
    <WallOfFame/>
  </AppPage>
</template>
