<script>
import Panel from "primevue/panel";
import Inplace from 'primevue/inplace';
import Button from "primevue/button";
import Message from "primevue/message";
import OverlayPanel from 'primevue/overlaypanel';


export default {
  components: {Panel, Inplace, Button, Message, OverlayPanel},
  data: () => ({
    questions: [
      'How are you doing?',
      'What happened this week?',
      'Provide detail on scores that are not threes.',
      'Say hello.',
      'What are you celebrating?',
      'What are you worried about?',
      'What do you hate about work?',
      'What can be better for you?',
      'Does your desk neighbor chew too loudly?',
      'Did you hate company meeting?',
      'Are you excited about a new productivity tool?',
      'Do you have a headache?',
      'Is there something we can do to support you?'
    ]
  })
}
</script>
<template>
  <div>

    <Panel ref="op" collapsed toggleable>
      <template #header>
        <Button link label="Not sure what to write? Click for some hints" @click="$refs.op.toggle()"/>
      </template>
      <div>
        <p>
          While we are excited to understand how you're doing across all of your dimensions, we really want to just hear
          from you and understand how everything is going.
        </p>
        <p>
          So here are a few examples of what to put in the section above if you are unsure what to write.
        </p>
        <ul>
          <li v-for="q in questions">{{ q }}</li>
        </ul>
      </div>
    </Panel>
  </div>

</template>