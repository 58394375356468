<script setup>
import {onMounted, ref} from 'vue';
import {backend} from "@/helpers";
import hOSAvatar from "@/components/hOSAvatar.vue";
import BadgeImage from "@/components/badge/BadgeImage.vue";

const users = ref([]);
onMounted(async () => {
  users.value = await backend.getWOF();
})

</script>

<template>
  <div v-if="users?.length > 0">
    <div class="flex flex-wrap w-full gap-4">
        <div class="min-w-[15rem] max-w-[15rem] " v-for="user in users">
          <div class="bg-surface-ground shadow rounded-border border-surface border p-6 h-full">
            <div class="flex flex-col items-center mb-4">
              <div class="flex flex-col">
                <hOSAvatar :user="user" size="xlarge" class="!w-40 !h-40"/>
                <div class="flex -mt-10">
                  <BadgeImage :id="badge.id" v-for="badge in user.badges" class="-ml-7"/>
                </div>
              </div>
              <span class="font-medium text-2xl text-center">{{ user.name }}</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
