<script setup>
import {formatDate} from "@/helpers/date.helper";
import {computed} from "vue";
import {timeoffHelper as to} from "@/helpers/timeoff.helper";

const {day} = defineProps(['day'])

const decoration = computed(() => {
  switch (day.type) {
    case "OOO":
      return 'pulse-pill-ooo';
    case "PULSE":
      return 'pulse-pill-pulse';
    case "NO_INFO":
      return 'pulse-pill-noinfo';
    case "FUTURE":
      return 'pulse-pill-future';
    default:
      return 'primary';
  }
})

const tooltip = computed(() => {
  let tooltip = day.date;
  if (day.type == "OOO") {

    tooltip += `\n${to.byValue(day.text).label}`;
  }
  if (day.type == "NO_INFO") {
    tooltip += `\n${day.text}`;
  }
  return tooltip;
})

</script>

<template>
  <span class="pulse-pill"
        :class="decoration"
        v-tooltip.top="tooltip"
  >{{formatDate(day.date, 'EEE')}}</span>
</template>
