import {defineStore} from 'pinia';

import {backend, router} from '@/helpers';
import {profileBackend} from "@/backend/profile.backend";

async function parseJWT(jwt) {
    if (jwt) {
        return await profileBackend.getself();
    }
    return null
}

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        jwt: localStorage.getItem('jwt'),
        user: null,
        returnUrl: null
    }),
    getters: {
        isLeader: (state) => state.user?.is_leader,
        isReviewer: (state) => state.user?.is_reviewer,
        isAdmin: (state) => state.user?.is_admin,
        canSeePulse: (state) => state.user?.pulse_enabled || state.user?.is_admin,
    },
    actions: {
        async fromJWT(jwt) {
            try {
                localStorage.setItem('jwt', jwt);
                this.jwt = jwt;
                this.user = await parseJWT(jwt);
            } catch (e) {
                console.log(e);
            }
        },
        async refreshToken() {
            if (this.jwt) {
                console.log("Refreshing token");
                const response = await backend.refreshToken();
                await this.fromJWT(response.jwt);
            }
        },
        silentLogout() {
            console.log("Logging out");
            this.user = null;
            this.jwt = null;
            localStorage.removeItem('jwt');
            localStorage.removeItem('checkin');
            localStorage.removeItem('user');
        },
        logout() {
            this.silentLogout()
            router.push('/login');
        },
        _updateUser(user) {
            if (user) {
                // update pinia state
                this.user = user;

                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
        }
    }
});
