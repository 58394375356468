<script setup>
import {computed, onMounted, ref} from 'vue'
import {DateTime} from "luxon";
import Debug from "@/components/Debug.vue";
import PulseWeek from "@/components/pulse/PulseWeek.vue";
import WeekPicker from "@/components/WeekPicker.vue";
import pulseBackend from "@/backend/pulse.backend";
import BlockUI from "primevue/blockui";
import {useAuthStore} from "@/stores/index.js";

const {userId} = defineProps(['userId'])
const weekStart = ref(DateTime.now().startOf('week'))
const weekEnd = computed(() => weekStart.value.endOf('week', {}))
const inception = ref()
const pulses = ref()
const loading = ref(true)
const editable = ref(useAuthStore().user.id === userId)

async function fetchPulses() {
  loading.value = true
  pulses.value = await pulseBackend.get(userId, weekStart.value.toISODate())
  loading.value = false
}

onMounted(async () => {
  const inceptionResponse = await pulseBackend.inceptionDate(userId);
  inception.value = DateTime.fromISO(inceptionResponse.date)
  await fetchPulses()
})

const isLastWeek = computed(() => {
  return inception.value >= weekStart.value && inception.value <= weekEnd.value
})

const shouldShowWeekendButton = computed(() => {
  const afterFriday = DateTime.now().toISODate() > weekStart.value.plus({days: 4}).toISODate();
  const lessThanFullWeek = Object.keys(pulses.value || []).length < 6;
  return afterFriday && lessThanFullWeek;
})

</script>

<template>
  <div>
    <div class="flex flex-col">
        <WeekPicker v-model="weekStart" :is-last-week="isLastWeek" @change="fetchPulses"/>
    </div>

    <BlockUI :blocked="loading">
      <PulseWeek :pulses="pulses" :editable="editable"  class="pt-2" :show-weekend-button="shouldShowWeekendButton"/>

    </BlockUI>
    <Debug :data="pulses"/>
  </div>


</template>
