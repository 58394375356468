<script setup lang="ts">

import {computed, ref} from "vue";
import {usePeopleStore} from "@/stores/people.store.ts";
import MultiSelect from "primevue/multiselect";
import {useCoreValuesStore} from "@/stores/use.core.values.store.ts";
import {Shoutout} from "@/types/DataTypes.ts";
import ToggleButton from "@/components/ToggleButton.vue";
import Debug from "@/components/Debug.vue";
import RadioButton from "primevue/radiobutton";
import useAppToast from "@/composables/useAppToast";
import {shoutoutBackend} from "@/backend/shoutout.backend.ts";

const people = computed(() => usePeopleStore().people)
const coreValues = computed(() => useCoreValuesStore().coreValues)
const selectedValues = ref({} as any)
const shoutout = ref({} as Shoutout)
const canSubmit = computed(() => {
  return shoutout.value.receivers && shoutout.value.why && shoutout.value.delivery
})
const emit = defineEmits(['done'])
const submitting = ref(false)

const toaster = useAppToast()

const submitShoutout = async () => {
  try {
    submitting.value = true
    shoutout.value.coreValues = Object.keys(selectedValues.value).filter(key => selectedValues.value[key])
    await shoutoutBackend.submit(shoutout.value)
    emit('done')
    toaster.success("Gotcha!")
    submitting.value = false
  } catch (e) {
    console.error("Error submitting shoutout", e)
    toaster.error("Error submitting shoutout. " + e)
  }
}

</script>

<template>
  <div class="grid grid-cols-3 w-[50rem] gap-4">
    <MultiSelect v-model="shoutout.receivers" :options="people" optionLabel="name" optionValue="id" class="w-full col-span-3"
                 display="chip" :show-toggle-all="false"
                 placeholder="Who are you giving a Shout Out to?" filter
    />
    <div>
      <p>Why are you giving a Shout Out?</p>
      <p class="text-muted-color text-xs">
        (great job on a project, helped you reach a goal, showed support in an impactful way, etc).
        Please keep your shoutout to 3 sentences max.
      </p>
    </div>
    <Textarea class="w-full col-span-2" v-model="shoutout.why" rows="3" auto-resize/>
    <div>
      <p>Is the Shout Out applicable to any of our core values?</p>
      <p class="text-muted-color text-xs"> (check all that apply)</p>
    </div>
    <div class="flex flex-wrap gap-2 col-span-2">

      <ToggleButton v-for="coreValue in coreValues" v-model="selectedValues[coreValue.key]" :key="coreValue.key"
                    :label="coreValue.name" size="small" class="text-xs"/>
    </div>
    <div>
      <p>Would you like to deliver this Shout Out in our next Company Meeting?</p>
      <p class="text-muted-color text-xs">
        We ask that shoutouts are delivered in 45 seconds or less to keep company meeting on track for time.
      </p>
    </div>
    <div class=" col-span-2">
      <div>
        <RadioButton v-model="shoutout.delivery" value="self" inputId="self" name="delivery"/>
        <label for="self" class="ml-2">I'd be happy to give the Shout Out in 45 seconds or less</label>
      </div>
      <div>
        <RadioButton v-model="shoutout.delivery" value="behalf" inputId="behalf" name="delivery"/>
        <label for="behalf" class="ml-2">I'd rather you give the Shout Out on my behalf</label>
      </div>
      <div>
        <RadioButton v-model="shoutout.delivery" value="anonymous" inputId="anonymous" name="delivery"/>
        <label for="anonymous" class="ml-2">I'd like to remain anonymous</label>
      </div>
    </div>
    <div class="col-span-3">
      <Button label="Submit Shout Out" class="mt-4" :disabled="!canSubmit" @click="submitShoutout" :loading="submitting"/>
    </div>
    <Debug :data="{selectedValues, shoutout}" />
  </div>
</template>
