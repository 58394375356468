<script setup>
import {backend} from "@/helpers";
import AppPage from "@/components/AppPage.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Weekdays from "@/components/Weekdays.vue";
import Tag from "primevue/tag";
import InputGroup from "primevue/inputgroup";
import Message from "primevue/message";
import Select from "primevue/select";
import Popover from "primevue/popover";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import SlackButton from "@/components/SlackButton.vue";
import PersonRetro from "@/components/retro/PersonRetro.vue";
import Skeleton from "primevue/skeleton";
import {DateTime} from "luxon";
import {todayWeek} from "@/composables/useRetroWeek";
import RetroWeekPicker from "@/components/retro/RetroWeekPicker.vue";

const retros = ref();
const selectedRetro = ref();
const selectedWeek = ref();
const nameFilter = ref();
const fontSize = ref(1.5);
const loaded = ref(false);
const retro = ref();
const route = useRoute();
const router = useRouter()
const shownMising = ref();
const missingInfo = ref();


onMounted(async () => {
  selectedWeek.value = await todayWeek();
  retros.value = await backend.myretros();
  await selectFromParams()
  await init();
})


const missingSelf = computed(() => {
  return retro.value.entries.filter(e => e.missingSelf && hasWorkingDays(e.weekdays));
})

const missingLead = computed(() => {
  return retro.value.missingLead;
})

const teams = computed(() => {
  if (!selectedRetro.value || !retro.value?.entries)
    return [];
  if (!selectedRetro.value.teams)
    return [{name: '', people: retro.value.entries}];

  const teams = {};
  retro.value.entries.forEach(p => {
    const team = p.team || '';
    if (!teams[team]) teams[team] = [];
    teams[team].push(p);
  });

  return Object.keys(teams)
      .map(k => ({name: k, people: teams[k]}))
      .sort((a, b) => b.name != '' ? a.name.localeCompare(b.name) : a.name.localeCompare('zzz'));
})

async function init() {
  loaded.value = false;
  if (selectedRetro.value) {
    await router.push(`/retro/${selectedRetro.value.id}/${selectedWeek.value.toISODate()}`);
    retro.value = await backend.getRetro(selectedRetro.value.id, selectedWeek.value.toISODate());
  }
  loaded.value = true
}


async function selectFromParams() {
  const retroId = route.params.id;
  const weekId = route.params.week;
  retros.value.filter(r => r.id == retroId).forEach(r => selectedRetro.value = r);
  if (weekId)
    selectedWeek.value = DateTime.fromISO(weekId);
}

function countMatchesFilter(people) {
  return people.filter(p => matchesFilter(p)).length;
}

function matchesFilter(entry) {
  if (!nameFilter.value)
    return true;
  const matches = name => name && name.toLowerCase().indexOf(nameFilter.value) >= 0;
  return matches(entry.name) || entry.checkins.some(c => matches(c.leader?.name));
}

function sortByName(people) {
  return people.sort((a, b) => a.name.localeCompare(b.name));
}


function toggleMissingOverlay(event, retro) {
  shownMising.value = retro;
  missingInfo.value.toggle(event);
}

function hasWorkingDays(weekdays) {
  return weekdays.filter(w => w.type != 'OOO').length > 0;
}

</script>
<template>
  <AppPage title="People Retro" :loaded="!!(retros && selectedWeek)">
    <div class="w-full xl:w-[70rem] self-center">
      <div class="flex flex-wrap gap-8 justify-start mb-4">
        <div>
          <Select v-model="selectedRetro" :options="retros" optionLabel="name" placeholder="Select a retro"
                  @change="init"/>
        </div>
        <div>
          <RetroWeekPicker v-model="selectedWeek" @change="init" />
        </div>
        <div>
          <InputText v-model="nameFilter" placeholder="filter by name"/>
        </div>
        <div>

          <InputGroup class="!items-center">
            <span class="p-text-secondary">Font:</span>
            <Button icon="pi pi-minus" @click="() => fontSize -= 0.1" size="small" text severity="secondary"/>
            <span class="p-text-secondary">{{ fontSize.toFixed(1) }}</span>
            <Button icon="pi pi-plus" @click="() => fontSize += 0.1" size="small" text severity="secondary"/>
          </InputGroup>
        </div>
      </div>
      <div v-if="loaded">
        <Popover ref="missingInfo">
          <div class="flex flex-row">

            <Weekdays v-if="shownMising" :weekdays="shownMising.weekdays" class="mr-4"/>
            <SlackButton :person="shownMising"/>
          </div>
        </Popover>
        <div class="flex flex-col gap-4 mb-4 " v-if="retro?.entries?.length">
          <Message severity="warn" v-if="missingSelf?.length > 0">
            <div>
              <strong>Missing self checkins:</strong>
              <p>
              <span v-for="r in missingSelf" class="mr-2">
                <Tag severity="secondary" class="mr-2 mb-2"
                     @click="e => toggleMissingOverlay(e, r)"
                     style="cursor: pointer">{{ r.name }} </Tag>
              </span>
              </p>
            </div>
          </Message>

          <Message severity="warn" v-if="missingLead?.length">
            <strong>Missing lead checkins:</strong>
            <p>
              <Tag severity="secondary" class="mr-2 mb-2"
                   v-for="l in missingLead"
                   @click="e => toggleMissingOverlay(e, l)"
                   style="cursor: pointer">
                {{ l.name }}
              </Tag>
            </p>
          </Message>
        </div>

        <div v-for="team in teams" :key="`${team.name}-${selectedRetro?.id}-${selectedWeek?.toISODate()}`">

          <div class="sticky top-0 z-50 bg-surface-200 dark:bg-surface-600  overflow-auto drop-shadow-lg rounded-b-xl "
               v-if="selectedRetro.teams && countMatchesFilter(team.people)">
            <div class="flex justify-evenly p-0 ">
              <span class="text-2xl font-medium p-2 ">Team {{ team.name ? team.name : 'Other' }}</span>
            </div>
          </div>

          <div v-for="person in sortByName(team.people)" class="flex gap-4">
            <div
                v-if="matchesFilter(person)"
                class="flex flex-col gap-4 border-2 rounded-border border-surface p-4 shadow mb-4 bg-surface-ground hover:bg-surface-0 dark:hover:bg-surface-900 w-full"
            >
              <PersonRetro :person="person" :fontSize="fontSize"/>
            </div>
          </div>
        </div>
      </div>
      <Skeleton v-else />
    </div>

  </AppPage>


</template>
