<script setup>
import SKSPostCard from "@/components/SKSPostCard.vue";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";

import {computed} from "vue";

const {skses} = defineProps(['skses'])
const categories = ['stop', 'keep', 'start', 'additional'];

const posts = computed(() => {
  console.log("CALLING POSTS")
  const posts = {};
  for (const e of categories) {
    posts[e] = [];
  }
  for (const sks of skses) {
    for (const e of categories) {
      posts[e].push({text: sks[e], name: sks.name || 'anonymous'});
    }
  }
  return posts;
})

const categoryLabels = computed(() => {
  const labels = {};
  for (const cat of categories) {
    labels[cat] = `Things that you can ${cat.toUpperCase()} doing`;
  }
  labels['additional'] = 'Additional comments'
  return labels;
})


</script>
<template>
  <Accordion class="w-full">
    <AccordionPanel v-for="(key, i) in Object.keys(posts)" :value="`${i}`">
      <AccordionHeader>{{ categoryLabels[key] }}</AccordionHeader>
      <AccordionContent>
        <div class="flex flex-col gap-2">
          <div v-for="post in posts[key]">
            <SKSPostCard v-if="post.text" :sks-post="post.text" :name="post.name"/>
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>

</template>
