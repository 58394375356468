<script setup lang="ts">
import AppPage from "@/components/AppPage.vue";
import {useAuthStore} from "@/stores";
import {computed} from "vue";
import RouteTab from "@/components/RouteTab.vue";
import MyTimeoff from "@/components/timeoff/MyTimeoff.vue";
import PeopleTimeoff from "@/components/timeoff/PeopleTimeoff.vue";
import AllTimeoff from "@/components/timeoff/AllTimeoff.vue";

const MY_ROUTE = "/time-off";
const PEOPLE_ROUTE = "/time-off/people";
const ALL_ROUTE = "/time-off/all";


const tabs = computed(() => {
  const ts = [{label: "My", to: MY_ROUTE}]
  if (hasPeople()) {
    ts.push({label: "My people", to: PEOPLE_ROUTE})
  }
  ts.push({label: "All", to: ALL_ROUTE})
  return ts
})

function hasPeople() {
  return useAuthStore().isLeader
}

</script>
<template>
  <AppPage title="Time Off" :loaded="true" :tabs="tabs">

    <RouteTab :tab-route="MY_ROUTE">
      <MyTimeoff />
    </RouteTab>
    <RouteTab :tab-route="PEOPLE_ROUTE">
      <PeopleTimeoff />
    </RouteTab>

    <RouteTab :tab-route="ALL_ROUTE">
      <AllTimeoff />
    </RouteTab>

  </AppPage>


</template>
