<script setup>

import AppPage from "@/components/AppPage.vue";
import Button from "primevue/button";
import {onMounted, ref} from "vue";
import {backend, Toaster} from "@/helpers";
import {useToast} from "primevue/usetoast";
import InputText from "primevue/inputtext";
import PeopleSelector from "@/components/PeopleSelector.vue";
import Dialog from "primevue/dialog";
import NameEditor from "@/components/badge/NameEditor.vue";
import PictureEditor from "@/components/badge/PictureEditor.vue";
import BadgeImage from "@/components/badge/BadgeImage.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";

const badges = ref([]);
const loaded = ref(false);
const users = ref([])
const toaster = new Toaster(useToast());
const addBadgeDialog = ref(false);
const newBadgeName = ref();
const avatarKey = ref(0);
const expandedRows = ref([]);

onMounted(async () => {
  badges.value = await backend.getBadges();
  users.value = await backend.users();
  loaded.value = true;
})


function initCreate() {
  addBadgeDialog.value = true;
}

async function createBadge() {
  const newBadge = {name: newBadgeName.value};
  await saveBadge(newBadge);

  addBadgeDialog.value = false;
  newBadgeName.value = '';
}

async function saveBadge(badge) {
  try {
    badges.value = await backend.saveBadge(badge);
    toaster.success("Badge saved", badge.name);
  } catch (e) {
    toaster.error(e);
  }
}

async function addPeople(badge, people) {
  try {
    badge.userBadges.push(...people.map(p => ({badge: badge, user: p})));
    await backend.addUsersToBadge({id: badge.id, userIds: people.map(p => p.id)});
    toaster.success("People added to badge", badge.name);
  } catch (e) {
    toaster.error(e);
  }
}

async function removePeople(badge, people) {
  try {
    const userIds = people.map(p => p.id);
    badge.userBadges = badge.userBadges.filter(ub => !userIds.includes(ub.user.id));
    await backend.removeUsersFromBadge({id: badge.id, userIds: people.map(p => p.id)});
    toaster.success("People removed from badge", badge.name);
  } catch (e) {
    toaster.error(e);
  }
}

async function uploadPicture(badge, event) {
  const file = event.files[0];
  await backend.uploadPicture(badge.id, file);
  avatarKey.value++
}

function badgePeople(badge) {
  return badge.userBadges.map(u => u.user);
}
</script>

<template>
  <AppPage title="Badges" :loaded="loaded">
    <Dialog v-model:visible="addBadgeDialog" header="Give it a name" modal>
      <InputText v-model="newBadgeName" placeholder="badge name" autofocus/>
      <div class="flex flex-row justify-end gap-2 mt-2">
        <Button label="Cancel" severity="secondary" @click="addBadgeDialog = false"/>
        <Button label="Save" @click="createBadge"/>
      </div>
    </Dialog>

    <div>
      <Button icon="pi pi-plus" label="New Badge" @click="initCreate"/>
    </div>

    <DataTable
        :value="badges"
        :expanded-rows="expandedRows" data-key="id"
    >
      <Column expander style="width: 1rem"/>
      <Column style="width: 1rem">
        <template #body="{data}">
          <BadgeImage :id="data.id" class="w-24 h-24" :key="avatarKey"/>
        </template>
      </Column>
      <Column field="name" header="Badge" :sortable="true">
        <template #body="{data}">
          <div class="items-group justify-items-start">
            <Tag :value="data.userBadges.length" rounded></Tag>
            <NameEditor v-model="data.name" class="xl:w-auto" @update:modelValue="saveBadge(data)"/>
          </div>
        </template>
      </Column>
      <template #expansion="{data}">
        <div class="flex items-start gap-4">
          <PeopleSelector :people="badgePeople(data)" class="container gy-3 w-[30rem]"
                          @people:add="(people) => addPeople(data, people)"
                          @people:remove="(people) => removePeople(data, people)"
                          :users="users"
          />
          <PictureEditor :badge="data" :toaster="toaster" @upload="uploadPicture(data, $event)"/>
        </div>
      </template>
    </DataTable>
  </AppPage>
</template>
