<script setup lang="ts">

import {computed, onMounted, ref} from "vue";
import {timeoffBackend} from "@/backend/timeoff.backend.ts";
import TimeOffList from "@/components/timeoff/TimeOffList.vue";
import {isSelf} from "@/composables/useAppUser.ts";
import TimeoffApprover from "@/components/timeoff/TimeoffApprover.vue";
import appUser from "@/composables/useAppUser.ts";

const { profile } = defineProps(['profile'])

const timeoffList = ref(await timeoffBackend.getPersonTimeoff(profile.user.id))

async function refreshTimeoffs() {
  timeoffList.value = await timeoffBackend.getPersonTimeoff(profile.user.id)
}

const isApprover = computed(() => {
  return profile.relations.leader || appUser().is_admin
})

const canSeeComments = computed(() => {
  return isApprover.value || isSelf(profile.user)
})

onMounted(async () => {
})

</script>

<template>
  <div>
    <TimeOffList :timeoffList="timeoffList" :hide-name="true" :hide-comment="!canSeeComments" v-if="timeoffList">
      <template #timeoffActions="slotProps">
        <TimeoffApprover :timeoff="slotProps.data" @refresh="refreshTimeoffs" v-if="isApprover" />
      </template>
    </TimeOffList>
  </div>
</template>
