<script>

import Button from "primevue/button";
import {Toaster} from "@/helpers";
import {timeoffBackend} from "@/backend/timeoff.backend";

export default {
  props: ['timeoff'],
  emits: ['refresh'],
  components: {Button},
  data: () => ({
    show: false,
    popover: {
      visibility: 'click',
    },
  }),

  computed: {
    toaster() {
      return new Toaster(this.$toast);
    },
  },

  methods: {
    async approveTimeoff() {
      try {
        await timeoffBackend.approveTimeoff({'id': this.timeoff.id})
        this.toaster.success('Time off approved')
        this.$emit('refresh');
      } catch (e) {
        this.toaster.error(e.message)
      }
    },

    async declineTimeoff() {
      try {
        await timeoffBackend.declineTimeoff({'id': this.timeoff.id})
        this.toaster.success('Time off declined')
        this.$emit('refresh');
      } catch (e) {
        this.toaster.error(e.message)
      }
    },
  }
}
</script>

<template>
  <Button icon="pi pi-check" text severity="success" @click="approveTimeoff"/>
  <Button icon="pi pi-ban" text severity="danger" @click="declineTimeoff"/>
</template>
