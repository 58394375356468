import {request, submitFile} from "@/backend/backend_util.ts";
import {CheckinData} from "@/types/DataTypes.ts";

export type UserProfileType = {
    id: number,
    username: string,
    email: string,
    meta: {[key: string]: any},
    pulse_enabled: boolean,
    name: string,
    title: string,
    department: any,
}
export type ProfileRelationType = {
    leader: boolean,
    reviewer: boolean
}

export type ProfileResponseType = {
    user: UserProfileType,
    relations: ProfileRelationType
}

export const profileBackend = {
    getself: request<UserProfileType>('GET', '/profile'),
    get: (username: string) => request<ProfileResponseType>('GET', '/profile/' + username)(),
    getCheckins: (userId: number, weekOf: string) => request<CheckinData[]>('GET', `/profile/${userId}/checkins/${weekOf}`)(),
    update: request<ProfileResponseType>('PUT', '/profile'),
    uploadAvatar: (userId: number, file: File) => submitFile('POST', `/profile/${userId}/avatar`, file),
}
