// Time Off
import {request} from "@/backend/backend_util.ts";

export type TimeoffDTO = {
    id: number;
    start: string;
    end: string;
    status: string;
    comment: string;

}

export const timeoffBackend = {
    getTimeoff: request<TimeoffDTO[]>('GET', '/time-off'),
    getPeopleTimeoffs: request<TimeoffDTO[]>('GET', '/time-off/people'),
    getAllTimeoff: request<TimeoffDTO[]>('GET', '/time-off/all'),
    getPersonTimeoff: (userId: number) => request<TimeoffDTO[]>('GET', '/time-off/' + userId)(),
    saveTimeoff: request<TimeoffDTO>('POST', '/time-off'),
    deleteTimeoff: request('DELETE', '/time-off'),
    approveTimeoff: request('POST', '/time-off/people/approve'),
    declineTimeoff: request('POST', '/time-off/people/decline'),

}
