<script setup>
import {backend} from "@/helpers";
import AddUsers from "../../components/AddUsers.vue";
import AppPage from "@/components/AppPage.vue";
import InputText from "primevue/inputtext";
import Badge from "primevue/badge";
import Select from "primevue/select";
import {computed, onMounted, ref} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import hOSAvatar from "@/components/hOSAvatar.vue";
import {useRouter} from "vue-router";

const users = ref();
const selected = ref(null);
const nameFilter = ref("");
const roleFilter = ref("");
const filterOptions = ref([
  {label: 'All roles', value: ''},
  {label: 'Leaders', value: 'leader'},
  {label: 'Reviewers', value: 'reviewer'},
  {label: 'Admins', value: 'admin'}
]);
const loaded = ref(false);
onMounted(async () => {
  await refreshUsers();
});

const filteredUsers = computed(() => {
  let filteredUsers = users.value;
  switch (roleFilter.value) {
    case 'leader':
      filteredUsers = filteredUsers.filter(u => u.is_leader);
      break;
    case 'admin':
      filteredUsers = filteredUsers.filter(u => u.is_admin);
      break;
    case 'reviewer':
      filteredUsers = filteredUsers.filter(u => u.is_reviewer);
      break;
  }
  let lowerFilter = nameFilter.value.toLowerCase();
  if (lowerFilter.length > 0)
    filteredUsers = filteredUsers.filter(u => u.name.toLowerCase().indexOf(lowerFilter) >= 0);
  return filteredUsers;
})

async function refreshUsers(resetSelection) {
  loaded.value = false;
  users.value = await backend.users();
  if (resetSelection) selected.value = null;
  loaded.value = true;
}

const router = useRouter();
function openUser(event) {
  router.push('/profile/' + event.data.username)
}
</script>
<template>
  <AppPage title="Users" :loaded="loaded">
    <div class="flex gap-2">
      <Select v-model="roleFilter" :options="filterOptions" optionLabel="label" optionValue="value"
              placeholder="by role"/>
      <InputText v-model="nameFilter" placeholder="by name" class="xl:w-auto"/>
    </div>
    <DataTable
        :value="filteredUsers"
        selection-mode="single"
        @row-select="openUser"
        scrollable
        scroll-height="65vh"
        style="min-height: 65vh"
    >
      <Column style="width: 1rem">
        <template #body="{data}">
          <hOSAvatar :user="data" size="small"/>
        </template>
      </Column>
      <Column field="name" header="Name" :sortable="true"/>
      <Column field="email" header="Email" :sortable="true"/>
      <Column field="pulse_enabled" header="Pulse" :sortable="true" style="width: 1rem">
        <template #body="{data}">
          <i class="pi pi-check" v-if="data.pulse_enabled"/>
        </template>
      </Column>
      <Column header="Roles">
        <template #body="{data}">
          <div class="flex flex-row gap-2">
            <Badge value="leader" severity="contrast" v-if="data.is_leader"/>
            <Badge value="reviewer" severity="contrast" v-if="data.is_reviewer"/>
            <Badge value="admin" severity="contrast" v-if="data.is_admin"/>
          </div>
        </template>
      </Column>
      <Column>
        <template #body="{data}">
          <Badge :value="data.people?.length" severity="secondary" v-if="data.is_leader"/>
        </template>
      </Column>
    </DataTable>
    <div class="grid grid-cols-12 gap-4 justify-start">
      <div class="col-span-4 flex flex-col">

      </div>
      <div class="col-span-8">
      </div>
    </div>

    <AddUsers @refresh-users="refreshUsers"/>

  </AppPage>
</template>
