import {request} from "./backend_util.ts";
import {CompanyPulseData, PulseData} from "../types/DataTypes.ts";

const pulseBackend = {
    post: request<PulseData>('POST', '/pulse'),
    get: (userId: number, weekStart: string) => request('GET', `/pulse/user/${userId}/${weekStart}`)(),
    inceptionDate: (userId: number) => request('GET', `/pulse/user/${userId}/inception-date`)(),
    company: (weekOf: string) => request<CompanyPulseData[]>('GET', `/pulse/company/${weekOf}`),
    comment: (id: number, comment: string) => request('POST', `/pulse/comment/`)({id, comment}),
    fetchComments: (id: number) => request('GET', `/pulse/${id}/comments/`)(),
}

export default pulseBackend;
