<style scoped>
.retro-card {
  @apply mb-4 border rounded-border border-surface p-4
}
</style>
<script setup lang="ts">

import PulseWeek from "@/components/pulse/PulseWeek.vue";
import PulseWeekToggle from "@/components/pulse/PulseWeekToggle.vue";
import Weekdays from "@/components/Weekdays.vue";
import hOSAvatar from "@/components/hOSAvatar.vue";
import {computed} from "vue";
import MissingCheckinCard from "@/components/retro/MissingCheckinCard.vue";
import CheckinCommenter from "@/components/checkin/CheckinCommenter.vue";
import Button from "primevue/button";
import {backend} from "@/backend/backend";
import CheckinCard from "@/components/CheckinCard.vue";

const {person} = defineProps(['person', 'fontSize'])

const selfCheckin = computed(() => {
  return person.checkins.find((c: any) =>!c.leader);
})

const leadCheckins = computed(() => {
  return person.checkins.filter((c: any) => c.leader);
})

async function acknowledgeSelfCheckin() {
  if (selfCheckin) {
    let updatedEntry = await backend.acknowledgeCheckin(selfCheckin.value.id)();
    selfCheckin.value.acknowledgedById = updatedEntry.acknowledgedById;
  }
}

function markAcknowledged() {
  console.log('markAcknowledged');
  selfCheckin.value.acknowledgedById = true;
}

</script>

<template>
  <div class="flex flex-row justify-between">
    <div class="flex items-center">
      <hOSAvatar :user="person" size="large" class="mr-2"/>
      <span class="text-2xl"
            :class="{'p-text-secondary' : person.checkins.length == 0}">{{ person.name }}</span>
    </div>

    <div class="flex flex-row items-center">
      <Weekdays :weekdays="person.weekdays" class="mr-4"/>
      <PulseWeekToggle :selector="`pulse-${person.id}`" :enabled="person.pulse_enabled" />
    </div>
  </div>

  <PulseWeek :pulses="person.weekdays" :id="`pulse-${person.id}`" class="hidden p-2"/>

  <!--  Self checkin card-->
  <CheckinCard :entry="selfCheckin" :self="true" :font-size="fontSize" v-if="selfCheckin" :readonly="true"
               class="retro-card" :key="selfCheckin.id">
    <template #left>

      <div class="flex gap-2">
        <Button v-if="!selfCheckin.acknowledgedById" icon="pi pi-check-circle" size="small"
                @click="acknowledgeSelfCheckin()" outlined v-tooltip.top="'Acknowledge'"/>
        <CheckinCommenter :checkin="selfCheckin" :checkin-owner-name="person.name" class="grow"
                          @comment-added="markAcknowledged">
          <template #button="{showCallback, tooltip}">

            <Button icon="pi pi-slack" label="Comment" size="small" @click="showCallback" v-tooltip="tooltip"
                    class="w-full"/>
          </template>
        </CheckinCommenter>
      </div>
    </template>
  </CheckinCard>
  <MissingCheckinCard v-else :person="person"/>

  <!--  Lead checkins -->
  <CheckinCard v-for="entry in leadCheckins" :entry="entry" :self="false" :fontSize="fontSize" :readonly="true" class="retro-card" :key="entry.id">
    <template #left>
      <div class="flex flex-row items-center justify-end">
        <hOSAvatar :user="entry.leader" size="medium" class="mr-2" v-if="entry.leader"/>
        <span class="font-bold text-sm">{{ entry.leader.name }}</span>
        <CheckinCommenter :checkin="entry" :checkin-owner-name="entry.leader.name" class="ml-2" />
      </div>
    </template>
    <template #skip>
      <div class="flex flex-row items-center mt-2">
        <hOSAvatar :user="entry.leader" size="medium" class="mr-2" v-if="entry.leader"/>
        <span class="font-bold text-sm">{{ entry.leader.name }}</span>
      </div>
    </template>
  </CheckinCard>
</template>
