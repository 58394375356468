<script setup lang="ts">
import {computed, ref} from "vue";
import Dialog from "primevue/dialog";
import {useRouter} from "vue-router";
import {usePeopleStore} from "@/stores/people.store.ts";
import HOSAvatar from "@/components/hOSAvatar.vue";

const router = useRouter()
const value = ref("")
console.log("Global search init")
const people = computed(() => usePeopleStore().people)
const visible = ref(false)
const searchField = ref()
const selectedIndex = ref<number>(0)

const results = computed(() => {
  if (!value.value) return []
  const filtered = people.value.filter(user => user.name.toLowerCase().includes(value.value.toLowerCase()));
  selectedIndex.value = 0;
  return filtered
})

function show() {
  visible.value = true
  if (searchField.value) searchField.value.$el.focus()
}

function handleKeys(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    close()
  }
  // select next user on down arrow
  if (event.key === 'ArrowDown') {
    event.stopPropagation();
    if (selectedIndex.value < results.value.length - 1) {
      selectedIndex.value++
    }
  }

  // select previous user on up arrow
  if (event.key === 'ArrowUp') {
    event.stopPropagation();
    event.preventDefault();
    if (selectedIndex.value > 0) {
      selectedIndex.value--
    }
  }

  // Send to user profile on enter
  if (event.key === 'Enter') {
    goToUser()
  }
}

function goToUser() {
  const user = results.value[selectedIndex.value]
  router.push(`/profile/${user.username}`)
  value.value = ""
  close()
}

function focusInput() {
  searchField.value.$el.focus()
}

function close() {
  value.value = "";
  visible.value = false
}

defineExpose({show})

</script>

<template>
  <Dialog v-model:visible="visible"
          modal
          :closable="false" :draggable="false"
          @show="focusInput"
          pt:header:class="!p-0 !pb-5" position="top" @keyup="handleKeys"
  >
    <div class="flex flex-col">
      <InputText v-model="value" placeholder="Search for people" ref="searchField" class="!text-2xl mb-2"/>
      <div v-for="(result, i) in results"
           class="flex p-3 rounded-md cursor-pointer"
           :class="{selection: selectedIndex === i}"
           @mouseover="selectedIndex = i"
           @click="goToUser"
      >
        <div class="items-group">
          <h-o-s-avatar :user="result" :key="result.id"/>
          <span>{{ result.name }}</span>
        </div>
      </div>
      <span v-if="results.length === 0 && value">No results found</span>
    </div>
  </Dialog>
</template>

<style scoped>
  .selection {
    @apply bg-primary-50 dark:bg-primary-950;
  }
</style>