<script setup lang="ts">
import {computed} from "vue";

const {value} = defineProps(['value'])
const tag = computed(() => value.split(' ')[0])
</script>

<template>
  <Tag :value="`${tag}...`" severity="info" v-tooltip.top="value"/>
</template>
