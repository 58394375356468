<script setup lang="ts">

import TimeOffList from "@/components/timeoff/TimeOffList.vue";
import TimeoffApprover from "@/components/timeoff/TimeoffApprover.vue";
import {timeoffBackend, TimeoffDTO} from "@/backend/timeoff.backend.ts";
import {onMounted, ref} from "vue";

const peopleTimeoffs = ref<TimeoffDTO[] | null>(null);

const loadPeople = async () => {
  peopleTimeoffs.value = await timeoffBackend.getPeopleTimeoffs();
};

onMounted(async () => {
  await loadPeople();
});

</script>

<template>
  <TimeOffList :timeoffList="peopleTimeoffs" @mounted="loadPeople">
    <template #timeoffActions="slotProps">
      <TimeoffApprover :timeoff="slotProps.data" @refresh="loadPeople"/>
    </template>
    <template #empty>
      <h3>Your people haven't requested any time off yet</h3>
    </template>
  </TimeOffList>
</template>
