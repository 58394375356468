import {createRouter, createWebHashHistory} from 'vue-router'
import {
    AdminBadgesView,
    AdminGroupsView,
    AdminUsersView,
    CheckinView,
    Home,
    LoginView,
    ProfilePage,
    PulseView,
    RetroPage,
    SKSDetailsView,
    SKSFeedbackView,
    SKSPostView,
    TimeOffView,
    ShoutoutPage
} from '../views'
import {useAuthStore} from '@/stores';
import ConfigView from "@/views/admin/ConfigView.vue";
import PeopleView from "@/views/PeopleView.vue";

export const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    linkExactActiveClass: 'active',
    routes: [
        {path: '/', component: Home},
        {path: '/profile/:username?', component: ProfilePage},
        {path: '/checkin/:tab?', component: CheckinView},
        {path: '/time-off/:tab?', component: TimeOffView},
        {path: '/pulse/:tab?', component: PulseView},
        {path: '/sks', component: SKSFeedbackView},
        {path: '/sks/:slug', component: SKSDetailsView},
        {path: '/sks-post/:slug', component: SKSPostView, meta: {hideNavbar: true}},
        {path: '/login', component: LoginView},
        {path: '/retro/:id?/:week?', component: RetroPage},
        {path: '/people', component: PeopleView},
        {path: '/shout-out', component: ShoutoutPage},
        {path: '/admin/groups', component: AdminGroupsView},
        {path: '/admin/users', component: AdminUsersView},
        {path: '/admin/badges', component: AdminBadgesView},
        {path: '/admin/config', component: ConfigView},
    ]
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const loginPage = '/login';
    const isLoginPage = loginPage === to.path;

    const auth = useAuthStore();
    if (isLoginPage) {
        console.log(JSON.stringify(to.query.token));
        let token = to.query.token;
        let jwt = to.query.jwt;
        if (jwt) {
            auth.fromJWT(jwt);
            return '/';
        }
        if (token) {
            try {
                await auth.login(token);
                return '/';
            } catch (err) {
                console.log("Failed to authorize: ", err);
            }
        }

    } else if (!auth.jwt && !isPublic(to.path)) {
        return '/login';
    }
});

function isPublic(path) {
    const publicRoutes = ['/sks-post'];
    return publicRoutes.some(pr => path.startsWith(pr));
}
