<script setup>
import SKSPostCard from "@/components/SKSPostCard.vue";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";

defineProps(['skses'])
const categories = ['stop', 'keep', 'start', 'additional'];

</script>
<template>
  <Accordion>
    <AccordionPanel v-for="(sks, i) in skses" :value="`${i}`">
      <AccordionHeader>{{ sks.name || 'anonymous' }}</AccordionHeader>
      <AccordionContent>
        <div class="flex flex-col gap-2">
          <div v-for="cat in categories">
            <SKSPostCard :sks-post="sks[cat]" :header="cat.toUpperCase()" v-if="sks[cat]"/>
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>
