<script setup>
import {useAuthStore} from "@/stores";
const authStore = useAuthStore();
</script>

<template>
  <div class="bg-surface mr-4 flex items-center justify-between relative lg:static lg:hidden " v-if="authStore.user">
    <a v-ripple class="cursor-pointer block text-surface-700 dark:text-surface-100"
       v-styleclass="{ selector: '#app-sidebar', enterFromClass: 'hidden', enterActiveClass: 'animate-fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeoutleft', hideOnOutsideClick: true }">
      <i class="pi pi-bars"></i>
    </a>

  </div>
</template>
