import {useAuthStore} from "@/stores";

// @ts-ignore
const API_URL = import.meta.env.VITE_API_URL + "/api"

export function request<T>(method: "POST" | "GET" | "DELETE" | "PUT", url: string): (body?: any) => Promise<T> {
    return async (body?: any) => {
        const headers = new Headers();
        authHeader(headers);
        const requestOptions: RequestInit = {
            method,
            headers
        };
        if (body) {
            headers.set('Content-Type', 'application/json');
            requestOptions.body = JSON.stringify(body);
        }
        // console.log("TS request headers", requestOptions);
        try {
            let response = await fetch(API_URL + url, requestOptions);
            return await handleResponse(response);
        } catch (e) {
            console.error("Error in request", e);
            throw e;
        }
    }
}

// helper functions
function authHeader(headers: Headers) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { jwt } = useAuthStore();
    const isLoggedIn = !!jwt;
    if (isLoggedIn) {
        headers.set('Authorization', `Bearer ${jwt}`);
    }
}

async function handleResponse<T>(response: Response): Promise<T> {
    // console.log("Handling the response");
    let text = await response.text();
    if (!response.ok) {
        if (response.status == 401) {
            // auto logout if 401 Unauthorized returned from api
            useAuthStore().logout()
        }
        console.log("Rejecting the response");
        return Promise.reject(response.statusText);
    }
    return text && JSON.parse(text);
}

export function submitFile(method: string, url: string, file: File) {
    const headers = new Headers();
    authHeader(headers);
    const requestOptions: RequestInit = {
        method,
        headers
    };
    console.log("Sending file", file);
    let data = new FormData();
    data.append("file", file);
    console.log("Form data: ", data);
    // requestOptions.headers['Content-Type'] = `multipart/form-data`;
    requestOptions.body = data;
    // console.log("request headers", requestOptions);
    return fetch(API_URL + url, requestOptions).then(handleResponse);
}
