export const CheckinHelper = {
    hasDimensions: function (entry) {
        return entry.happiness > 0
            && entry.productivity > 0
            && entry.passion > 0;
    },

    hasSomeData(entry) {
        return entry.happiness > 0
            || entry.productivity > 0
            || entry.passion > 0
            || (entry.comment && entry.comment !== "")
    },

    hasAllData(entry) {
        return this.hasDimensions(entry) && entry.comment
    },

    isSkipped(entry) {
        return entry.skip;
    },

    isEntryFilled(entry) {
        if (!entry) return false;
        return this.hasAllData(entry) || this.isSkipped(entry);
    },

    countFilled(entries) {
        return entries.filter(e => this.isEntryFilled(e)).length;
    },
    isCheckinFilled(entries) {
        return this.countFilled(entries) === entries.length;
    },

    skipReasons() {
        return {
            'ooo': 'They were out of office',
            'no1to1': "Didn't have 1-1 with them",
        }
    }

}

