<script setup>
import Textarea from "primevue/textarea";
const {config} = defineProps(['config'])
if (!config.pulse) {
  config.pulse = {
    prompts: ""
  }
}
</script>

<template>

  <div class="flex flex-col gap-2">
    <label for="pulse-prompts">Pulse prompts</label>
    <Textarea v-model="config.pulse.prompts" id="pulse-prompts" class="w-full" rows="15"/>
  </div>
</template>
