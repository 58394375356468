import {defineStore} from "pinia";
import {ref} from "vue";
import {Person} from "@/types/DataTypes.ts";
import {peopleBackend} from "@/backend/people.backend.ts";

export const usePeopleStore = defineStore('users', () => {
    const people = ref([] as Person[]);
    peopleBackend.getList().then((data) => {
        console.log("People loaded")
        people.value = data;
    })

    return {
        people,
    }
});