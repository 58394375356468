<script setup>
import {computed} from "vue";

const {status} = defineProps(['status'])
const severity = computed(() => {
  switch(status) {
    case 'approved':
      return 'success'
    case 'pending':
      return 'secondary'
    case 'declined':
      return 'danger'
  }
})

</script>

<template>
  <Tag :value="status" :severity="severity" class="text-xs"/>
</template>
