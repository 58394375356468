import {DateTime} from "luxon";

export function formatDate(date, format) {
    if (!date) return "";

    const luxonDate = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
    return luxonDate.toFormat(format);
}

export function humanDateISO(date) {
    return formatDate(date, 'yyyy-MM-dd');
}

export function humanDateMMMdd(date) {
    return formatDate(date, 'MMM dd')
}

export function humanDateMMMddyyyy(date) {
    return formatDate(date, 'MMM dd, yyyy')
}
export function humanDateEEEMMMdd(date) {
    return formatDate(date, 'EEE, MMM dd')
}

export function humanDateTime(date) {
    return formatDate(date, "MMM dd, yyyy HH:mm");
}

