import retroBackend from "@/backend/retro.backend.ts";
import {DateTime} from "luxon";

let todayWeekValue: DateTime;

export async function todayWeek() {
    if (!todayWeekValue) {
        todayWeekValue = await retroBackend.getCurrentWeek();
    }
    return todayWeekValue;
}





