<script>

import Dimension from "@/components/Dimension.vue";
import {backend} from "@/helpers";
import {RouterLink} from "vue-router";
import AppPage from "@/components/AppPage.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import SKSFeedbackCard from "@/components/SKSFeedbackCard.vue";
import Select from "primevue/select";

export default {
  components: {SKSFeedbackCard, RouterLink, Dimension, AppPage, Button, Dialog, InputText, Select},
  data: () => ({
    ownSks: [],
    sharedSks: [],
    editedSKS: null,
    editing: false,
    confidants: [],
    loaded: false
  }),
  async mounted() {
    this.initSks(await backend.getAllSKS());
    this.confidants = await backend.confidants();
    this.loaded = true;
  },
  methods: {
    initSks(response) {
      this.ownSks = response.own;
      this.sharedSks = response.shared
    },
    addSKS() {
      this.editedSKS = {name: "My SKS"};
      this.editing = true;
    },
    editSKS(sks) {
      this.editedSKS = sks;
      this.editing = true;
    },
    async saveSKS() {
      this.initSks(await backend.saveSKS(this.editedSKS));
      this.editedSKS = null;
      this.editing = false;
    },
    cancelEdit() {
      this.editedSKS = null;
      this.editing = false;
    }

  }
}

</script>
<template>
  <AppPage title="My SKS" :loaded="loaded">
    <div>
    <Button label="Add SKS" icon="pi pi-plus" @click="addSKS" severity="primary" outlined :disabled="editing"/>
    </div>
  <div class="flex justify-start gap-2">
    <SKSFeedbackCard v-for="s in ownSks" :sks="s" @edit="editSKS" @save="saveSKS" @cancel="cancelEdit"/>

    <Dialog v-model:visible="editing" modal header="Edit SKS">
      <div class="flex items-center gap-4 mb-4">
        <label for="name" class="font-semibold w-24">SKS Name</label>
        <InputText id="name" class="flex-auto" autocomplete="off" v-model="editedSKS.name"/>
      </div>
      <div class="flex items-center gap-4 mb-8">
        <label for="confidant" class="font-semibold w-24">Confidant</label>
        <Select id="confidant" class="flex-auto" autocomplete="off"
                  v-model="editedSKS.confidantId"
                  :options="confidants" option-label="name" option-value="id"/>
      </div>
      <div class="flex justify-end gap-2">
        <Button type="button" label="Cancel" severity="secondary" @click="cancelEdit"></Button>
        <Button type="button" label="Save" @click="saveSKS"></Button>
      </div>
    </Dialog>
  </div>
    <div class="mt-8">
      <h1 v-if="sharedSks?.length > 0">Shared with me</h1>
      <SKSFeedbackCard v-for="s in sharedSks" :sks="s" :readonly="true"/>
    </div>
  </AppPage>

</template>
