<script setup lang="ts">

import AppPage from "@/components/AppPage.vue";
import {onMounted, ref} from "vue";
import {shoutoutBackend} from "@/backend/shoutout.backend.ts";
import Debug from "@/components/Debug.vue";
import ShoutoutCard from "@/components/shoutout/ShoutoutCard.vue";
import Dialog from 'primevue/dialog';
import ShoutoutForm from "@/components/shoutout/ShoutoutForm.vue";
const shoutouts = ref()

async function refresh() {
  shoutouts.value = await shoutoutBackend.getShoutouts()
}

onMounted(async () => {
  await refresh();
})

const dialogVisible = ref(false)

async function submitDone() {
  dialogVisible.value = false
  await refresh()
}
</script>

<template>
  <AppPage title="Shout-Outs" :loaded="true" >
    <div>
      <Button label="Give a Shout-Out!" icon="pi pi-megaphone" class="mb-2" @click="dialogVisible = true"/>
    </div>
    <div class="flex flex-wrap w-full gap-4">
      <div class="min-w-[30rem] max-w-[30rem] " v-for="shoutout in shoutouts">
        <ShoutoutCard :shoutout="shoutout"/>
      </div>
    </div>
    <Dialog v-model:visible="dialogVisible" modal header="Give a Shout-out!">
      <ShoutoutForm @done="submitDone"/>
    </Dialog>

    <Debug :data="shoutouts" :expanded="true"/>
  </AppPage>
</template>
