<script setup lang="ts">
import useVersion from "@/composables/useVersion.ts";

const {isCurrentVersion} = useVersion()

function refreshPage() {
  window.location.reload();
}
</script>

<template>
  <div class="absolute contain-content -top-32 left-1/2 text-sm items-center text-center rounded-b-lg border border-t-0 p-4 bg-surface shadow-lg"
       :class="{'translate-y-32 duration-200': !isCurrentVersion}"
  >
      <div>
        <p>People app has gotten better!</p>
        <a class="text-link" @click="refreshPage">Refresh now</a>
      </div>
  </div>
</template>
