<script setup>
import FileUpload from "primevue/fileupload";
import {ref} from "vue";

const emit = defineEmits(['upload'])
const uploader = ref();

function upload(event) {
  emit('upload', event)
  uploader.value.clear()
}
</script>

<template>
  <FileUpload mode="basic"
              accept="image/*"
              choose-label="Change Picture"
              :maxFileSize="1000000" @select="upload" ref="uploader"/>
</template>
