<script setup lang="ts">

import Card from "primevue/card";
import {computed} from "vue";
const {trait} = defineProps(['title', 'trait'])
const traitList = computed(() => trait ? trait.split('\n').filter((t: string) => t.trim().length > 0) : [])
</script>

<template>
<Card pt:title:class="!text-sm" class="text" v-if="trait">
  <template #title>{{ title }}</template>
  <template #content>
    <ul class="list-disc pl-4">
      <li v-for="t in traitList">{{ t }}</li>
    </ul>
  </template>
</Card>
</template>
