import {request} from "@/backend/backend_util.ts";
import {CoreValue, Person} from "@/types/DataTypes.ts";

export const peopleBackend = {
    getList: request<Person[]>('GET', '/people/list'),
    getDirectory: request<Person[]>('GET', '/people'),
    departments: request('GET', '/people/departments'),
    coreValues: request<CoreValue[]>('GET', '/people/core-values'),
}

