<script>
import PickList from "primevue/picklist";
import Listbox from "primevue/listbox";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import hOSAvatar from "@/components/hOSAvatar.vue";
import Menu from "primevue/menu";
import InputText from "primevue/inputtext";
import Panel from "primevue/panel";
import ToggleSwitch from "primevue/toggleswitch";
import Popover from "primevue/popover";

export default {
  props: ['people', 'users', 'teams'],
  emits: ["people:add", "people:remove", "teams:toggle", "set:team"],
  components: {PickList, Listbox, Dialog, Button, DataTable, Column, hOSAvatar, Menu, InputText, ToggleSwitch, Popover, Panel},
  data: () => ({
    selectedPeople: [],
    selectedUsers: [],
    addTeamDialogVisible: false,
    newTeamName: "",
    expandedRowGroups: []
  }),
  updated() {
  },
  computed: {
    teamable() {
      return typeof this.$props.teams !== 'undefined';
    },
    notInGroup() {
      this.selectedUsers = [];
      const peopleIds = this.people.map(p => p.id);
      return this.people
          ? this.users.filter(u => !peopleIds.includes(u.id))
          : this.users;
    },
    teamsMenu() {
      const teams = new Set(this.people.filter(p => p.team).map(p => p.team));
      return [
        {
          label: 'Assign to a team',
          items: Array.from(teams).map(t => ({
            label: t,
            icon: 'pi pi-angle-right',
            command: () => this.setTeam(t)
          }))
        },
        {
          label: 'New team...',
          icon: 'pi pi-plus',
          command: () => this.addTeamDialogVisible = true
        }
      ];
    },
  },
  methods: {
    showAddPeoplePopover(event) {
      this.$refs.addUsersPopover.show(event);
    },
    addPeople() {
      this.$emit('people:add', this.selectedUsers);
      this.selectedUsers = [];
      this.$refs.addUsersPopover.hide();
    },

    removePeople() {
      this.$emit('people:remove', this.selectedPeople);
      this.selectedPeople = [];
    },
    toggleTeamMenu(event) {
      this.$refs.teamMenu.toggle(event);
    },

    async setTeam(team) {
      this.selectedPeople.forEach(p => p.team = team);
      this.$emit('set:team', this.selectedPeople, team);
      this.addTeamDialogVisible = false;
      this.newTeamName = "";
      this.selectedPeople = [];
    },

  }
}
</script>
<template>
<div>
  <Popover ref="addUsersPopover" @hide="selectedUsers = []">
    <Listbox v-model="selectedUsers"
             v-model:expandedRowGroups="expandedRowGroups"
             :options="notInGroup"
             optionLabel="name"
             placeholder="Add user"
             list-style="max-height: 30rem"
             multiple filter>
      <template #option="slotProps">
        <div class="flex items-center gap-2">
          <hOSAvatar :user="slotProps.option"/>
          <span>{{ slotProps.option.name }}</span>
        </div>
      </template>
    </Listbox>
    <div class="flex justify-end">
      <Button label="Add" @click="addPeople"/>
    </div>
  </Popover>
  <Menu ref="teamMenu" :model="teamsMenu" :popup="true"></Menu>
  <Dialog v-model:visible="addTeamDialogVisible" modal header="Give the team a name" :style="{ width: '25rem' }">
    <div class="flex items-center gap-4 mb-4">
      <InputText v-model="newTeamName" class="flex-auto" autocomplete="off" autofocus @keyup.enter="setTeam(newTeamName)"/>
    </div>
    <div class="flex justify-end gap-2">
      <Button type="button" label="Cancel" severity="secondary" @click="addTeamDialogVisible = false"></Button>
      <Button type="button" label="Save" @click="setTeam(newTeamName)"></Button>
    </div>
  </Dialog>
  <Panel header="People">
    <template #header>
      <div class="flex gap-2 items-center">
        <span class="p-panel-title">People</span>
        <Button severity="secondary" rounded outlined icon="pi pi-user-plus" @click="showAddPeoplePopover"
                v-tooltip.top="'Add users'"/>
        <Button severity="danger" rounded outlined icon="pi pi-user-minus" @click="removePeople"
                v-tooltip.top="'Remove selected'"
                :disabled="selectedPeople?.length == 0"/>
        <Button severity="secondary" rounded outlined icon="pi pi-star"
                v-tooltip.top="'Assign to a team'"
                @click="toggleTeamMenu"
                :disabled="selectedPeople?.length == 0"
                v-if="teams"/>

      </div>
    </template>
    <template #icons>
      <div class="flex items-center gap-1" v-if="teamable">
        <ToggleSwitch v-bind:model-value="teams" inputId="teams-toggle" @change="$emit('teams:toggle', !teams)"/>
        <label for="teams-toggle">Teams</label>
      </div>
    </template>
    <DataTable :value="people"
               v-model:selection="selectedPeople"
               selection-mode="multiple"
               striped-rows
               :pt="{thead: {style: 'display: none'}}"
               scrollable scroll-height="40rem"
               :row-group-mode="teams ? 'subheader' : ''"
               :group-rows-by="teams ? 'team' : ''"
              :sort-field="'name'"
               :multi-sort-meta="[{field: 'team', order: 1}, {field: 'name', order: 1}]"
              :sort-order="1"
              :sort-mode="teams ? 'multiple' : 'single'">

      <template #groupheader="slotProps">
        <strong>{{ slotProps.data.team || '(no team)' }}</strong>
      </template>
      <Column selection-mode="multiple" style="width: 3em"/>
      <Column field="team" header="Team" style="width: 3rem" v-if="teams"/>
      <Column style="width: 3rem">
        <template #body="slotProps">
          <hOSAvatar :user="slotProps.data"/>
        </template>
      </Column>
      <Column field="name"></Column>

    </DataTable>
  </Panel>
</div>
</template>
