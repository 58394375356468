<script setup lang="ts">

import useAppToast from "@/composables/useAppToast";
import {checkinBackend} from "@/backend/checkin.backend.ts";
import {ref} from "vue";
import {firstName, wrapInQuote} from "@/helpers/utils.ts";

const toaster = useAppToast();
type CheckinCommenterProps = {
  checkinOwnerName: string;
  checkin?: any;
  personId?: number;
  label?: string;
}
const {checkin, personId, checkinOwnerName} = defineProps<CheckinCommenterProps>();

type Payload = {
  comment: string;
  checkinId?: number;
  personId?: number;
}

const emit = defineEmits(['commentAdded'])

const commentPopover = ref();
const comment = ref("");


const root = ref()


function showPopover(e: any) {
  const selection = window.getSelection();

  if (selection && selection.toString().length > 0) {
    comment.value = wrapInQuote(selection.toString());
  }


  commentPopover.value.show(e);
}

async function saveComment() {
  try {
    const payload: Payload = {
      comment: comment.value
    };
    let response = null;
    if (checkin) {
      payload.checkinId = checkin.id;
      response = await checkinBackend.commentOnCheckin(payload)
      if (!checkin.comments)
        checkin.comments = [];
      checkin.comments.push(response);
      emit('commentAdded');
    } else {
      payload.personId = personId;
      await checkinBackend.commentOnSelfMissing(payload)
    }
    comment.value = ""
    toaster.success("Comment added!");
  } catch (e) {
    toaster.error("Error adding comment");
    console.log(e);
  }
  commentPopover.value.hide();
}

function getTooltip() {
  const name = checkinOwnerName ? firstName(checkinOwnerName) : '';
  if (checkin) {
    return `Comment on ${name}'s checkin`;
  }
  return `Ask ${name} about their week`;
}

function sendOnCmdEnter(e: KeyboardEvent) {
  if (e.key === 'Enter' && e.metaKey) {
    saveComment();
    e.preventDefault();
  }
}
</script>

<template>

  <div ref="root">
    <slot :showCallback="showPopover" :tooltip="getTooltip()" name="button">
      <Button icon="pi pi-slack" severity="secondary" text size="small" @click="showPopover" v-tooltip="getTooltip()"
              :label="label"/>
    </slot>
    <Popover ref="commentPopover" @hide="comment = ''">
      <div class="flex flex-col">
        <Textarea v-model="comment" rows="3" cols="30" autoResize @keydown="sendOnCmdEnter" autofocus/>
        <Button label="Send" icon="pi pi-save" class="mt-2" @click="saveComment" v-tooltip="'Press Cmd+Enter to send'"/>
      </div>
    </Popover>
  </div>
</template>
