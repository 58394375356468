<script setup lang="ts">

import {computed} from "vue";
import Button from "primevue/button";
import {firstName} from "@/helpers/utils.ts";

const {person} = defineProps(['person'])
// @ts-ignore
const SLACK_BASE = import.meta.env.VITE_SLACK_URL
const slackURL = computed(() => SLACK_BASE + '&id=' + person.slackId)
const personFirstName = computed(() => firstName(person.name))

</script>

<template>
  <Button as='a' icon="pi pi-slack" text :href="slackURL" size="small" outlined
          v-tooltip="`Slack ${personFirstName}`"

          severity="secondary"/>
</template>
