<script setup>
import {useRoute} from "vue-router";
import {computed} from "vue";

const props = defineProps(['to', 'icon', 'label', 'exact'])
const route = useRoute();

const activeDecoration = computed(() => {
  const matches = props.exact ? route.path == props.to : route.path.startsWith(props.to);
  return matches ?
      'bg-primary-50 text-primary-500 border-primary-500 border-r-2 dark:bg-primary-950 dark:text-primary-200 dark:border-primary-900' :
      '';
})
</script>

<template>
  <a v-ripple
     class="flex items-center cursor-pointer p-4 text-surface-700 dark:text-surface-100 hover:bg-surface-100 dark:hover:bg-surface-700 dark:hover:text-surface-100 duration-150 transition-colors "
     :class="activeDecoration">
    <i :class="`pi ${props.icon} mr-2`"></i>

    <span class="">{{ props.label }}</span>
    <slot />
  </a>
</template>
