export function firstName(name: string): string {
    return name.split(' ')[0];
}

export function firstNameOrMe(user: any, loggedInUser: any): string {
    return user.id === loggedInUser.id ? 'me' : firstName(user.name);
}

export function wrapInQuote(text: string) {
    if (!text) return '';
    return text.trim().split('\n').map(line => `> ${line}`).join('\n') + '\n';
}


export const departments = ['Operations', 'Coaching', 'Behavior Science']

export const personalities = [
    {code: 'INTJ', label: 'Architect (INTJ-A / INTJ-T)'},
    {code: 'INTP', label: 'Logician (INTP-A / INTP-T)'},
    {code: 'ENTJ', label: 'Commander (ENTJ-A / ENTJ-T)'},
    {code: 'ENTP', label: 'Debater (ENTP-A / ENTP-T)'},
    {code: 'INFJ', label: 'Advocate (INFJ-A / INFJ-T)'},
    {code: 'INFP', label: 'Mediator (INFP-A / INFP-T)'},
    {code: 'ENFJ', label: 'Protagonist (ENFJ-A / ENFJ-T)'},
    {code: 'ENFP', label: 'Campaigner (ENFP-A / ENFP-T)'},
    {code: 'ISTJ', label: 'Logistician (ISTJ-A / ISTJ-T)'},
    {code: 'ISFJ', label: 'Defender (ISFJ-A / ISFJ-T)'},
    {code: 'ESTJ', label: 'Executive (ESTJ-A / ESTJ-T)'},
    {code: 'ESFJ', label: 'Consul (ESFJ-A / ESFJ-T)'},
    {code: 'ISTP', label: 'Viruoso (ISTP-A / ISTP-T)'},
    {code: 'ISFP', label: 'Adventurer (ISFP-A / ISFP-T)'},
    {code: 'ESTP', label: 'Entrepreneur (ESTP-A / ESTP-T)'},
    {code: 'ESFP', label: 'Entertainer (ESFP-A / ESFP-T)'},
]

export function findPersonality(code: string) {
    return personalities.find(p => p.code === code)?.label || '';
}

export const traits = [
    {title: "My Passions", key: 'passions'},
    {title: "My Features (strengths)", key: 'features'},
    {title: "My Bugs (weaknesses)", key: 'bugs'},
    {title: "Things that Frustrate Me", key: 'frustrations'},
    {title: "My Motivators", key: 'motivators'},
    {title: "My Demotivators", key: 'demotivators'}
]
