<script setup lang="ts">

import {computed, Ref} from "vue";

const {pulses} = defineProps(['pulses']);

type Stat = {
  hits: number;
  misses: number;
}

type WeekStats = {
  [day: string]: Stat
}
/*
* {day: completion rate}
* */
const pulseStats: Ref<WeekStats | null> = computed(() => {
  if (!pulses) {
    return null;
  }
  const weekStats: WeekStats = {}
  for (const pulse of pulses) {
    for (const day of Object.keys(pulse.pulses)) {
      const stat = weekStats[day] || {hits: 0, misses: 0};
      if (pulse.pulses[day].type === 'PULSE')
        stat.hits++
      if (['NO_INFO', 'TODAY'].includes(pulse.pulses[day].type))
        stat.misses++
      weekStats[day] = stat
    }
  }
  return weekStats
});

function getStatLabel(stat: Stat) {
  if (stat.hits === 0 && stat.misses === 0) {
    return '-'
  }
  const percentage = Math.round(stat.hits / (stat.hits + stat.misses) * 100)
  return `${percentage}%`
}
</script>

<template>
  <div class="flex flex-row gap-2" v-if="pulseStats">
    <span v-for="day in Object.keys(pulseStats)" class="pulse-pill pulse-pill-future" :key="day">
      {{ getStatLabel(pulseStats[day]) }}
    </span>
  </div>
</template>
