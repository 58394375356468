import {ref} from "vue";
import {defineStore} from "pinia";
import {peopleBackend} from "@/backend/people.backend.ts";
import {CoreValue} from "@/types/DataTypes.ts";


export const useCoreValuesStore = defineStore('core-values', () => {
    const coreValues = ref([] as CoreValue[]);
    console.log("Loading core values")
    peopleBackend.coreValues().then((data) => {
        console.log("Core values loaded")
        coreValues.value = data;
    })

    return {
        coreValues,
    }
});