<script setup lang="ts">

import {humanDateMMMdd} from "@/helpers/date.helper";
import {computed} from "vue";
import {DateTime} from "luxon";

const weekStart = defineModel<DateTime>({required: true})
const emit = defineEmits(['change'])
type WeekPickerProps = {
  isLastWeek?: boolean
  todayWeek?: DateTime
}
const props = defineProps<WeekPickerProps>()

const isTodayWeek = computed(() => {
  const today = props.todayWeek || DateTime.now()
  return today >= weekStart.value && today <= weekStart.value.endOf('week')
})

function prevWeek() {
  weekStart.value = weekStart.value.minus({weeks: 1})
  emit('change')
}

function nextWeek() {
  weekStart.value = weekStart.value.plus({weeks: 1})
  emit('change')
}

</script>

<template>
  <div class="flex flex-row items-center border border-surface rounded-md place-self-start"
  >
    <Button icon="pi pi-chevron-left" outlined severity="secondary" text @click="prevWeek" :disabled="isLastWeek"/>
    <span class="">{{ humanDateMMMdd(weekStart) }} - {{ humanDateMMMdd(weekStart.endOf('week')) }}</span>
    <Button icon="pi pi-chevron-right" outlined severity="secondary" text @click="nextWeek"
            :disabled="isTodayWeek"/>
  </div>
</template>
