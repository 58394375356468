<script>

import SKSPerCategory from "@/components/SKSPerCategory.vue"
import SKSPerPost from "@/components/SKSPerPost.vue"
import SKSDisclaimer from "@/components/SKSDisclaimer.vue"


import {backend, Toaster} from "@/helpers";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import AppPage from "@/components/AppPage.vue";

export default {
  data: () => ({
    sksFeedback: [],
    consent: true,
    error: null,
    loaded: false
  }),
  components: {AppPage, SKSPerCategory, SKSPerPost, SKSDisclaimer, Dialog, Button, TabView, TabPanel},
  async mounted() {
    try {
      this.sksFeedback = await backend.getSKSDetails(this.$route.params.slug)()
      this.loaded = true;
    } catch (e) {
      this.toaster.error("Error fetching SKS details", e);
    }
  },
  computed: {
    toaster() {
      return new Toaster(this.$toast);
    }
  },
  methods: {}
}

</script>
<template>
  <AppPage title="SKS Feedback" :loaded="loaded">
  <Dialog v-model:visible="consent" modal header="Before you dive in" :closable="false" position="top">
    <p>Here are some hints on how to process the feedback</p>
    <div>
      <p><strong>Analyze results</strong></p>
      <p>
        As you’ve created the form yourself, only you can see the results. As a form of accountability we encourage you
        to talk with a trusted confidant about your results but only after you have had time to review and digest
        everything
        yourself.
      </p>
    </div>
    <div>
      <p><strong>Suggestions when reviewing your feedback</strong></p>
      <div>
        <ul>
          <li>React, then respond</li>
          <li>
            Resist the urge to “witch hunt” or become angry/resentful towards those that shared their feedback
          </li>
          <li>
            Remember this feedback is not meant to attack or tear you down but to help you improve as an individual
            across
            numerous dimensions of your life
          </li>
          <li>
            There is your reality vs. the perception that others have of you. Understand that this feedback from
            individuals is
            just some peoples perspective.
          </li>
          <li>
            If you need to talk with a team member or your manager do not hesitate to reach out. The purpose of this
            exercise is
            to gain insight and create the next version of you.
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-center">
      <Button label="Got it" @click="consent = false;"/>
    </div>
  </Dialog>
  <div>
    <TabView>
      <TabPanel header="by Category">
        <SKSPerCategory :skses="sksFeedback"/>
      </TabPanel>
      <TabPanel header="by Post">
        <SKSPerPost :skses="sksFeedback"/>
      </TabPanel>
    </TabView>
  </div>
  </AppPage>
</template>