<script setup lang="ts">
import InputText from "primevue/inputtext";
import {personalities, traits} from "@/helpers/utils.ts";
defineProps(['user'])
const emit = defineEmits(['update:user'])
const traitsRows = 6

function saveUser() {
  console.log('Saving user')
  emit('update:user')
}
</script>

<template>
  <div class="grid grid-cols-2 gap-4">
    <div>
      <label for="goal" class="form-label">Goal to achieve</label>
      <InputText v-model="user.meta.goal" class="w-full" @change="saveUser"/>
    </div>
    <div>
      <span class="form-label"><a href="https://www.16personalities.com/" target="_blank" class="text-link">MBTI</a> (optional)</span>
      <Select v-model="user.meta.mbti"
              :options="personalities"
              optionLabel="label"
              optionValue="code"
              class="w-full"
              @change="saveUser"
              :showClear="true"
              :key="user.meta.mbti"
              filter
      />
    </div>
    <div>
      <label for="funfact" class="form-label">Fun fact</label>
      <InputText v-model="user.meta.funfact" class="w-full" @change="saveUser"/>
    </div>
    <div></div>
    <div v-for="trait in traits">
      <label :for="trait.key" class="form-label">{{ trait.title }}</label>
      <Textarea v-model="user.meta[trait.key]" class="w-full" @change="saveUser" :rows="traitsRows"/>
    </div>
  </div>
</template>
