<script setup>

import AppPage from "@/components/AppPage.vue";

import PulseWeeklyView from "@/components/pulse/PulseWeeklyView.vue";
import RouteTab from "@/components/RouteTab.vue";
import CompanyPulse from "@/components/pulse/CompanyPulse.vue";
import {useAuthStore} from "@/stores/index.js";

const authStore = useAuthStore()

const tabs = []
if (authStore.user.pulse_enabled) {
  tabs.push({label: 'My Pulse', to: '/pulse', icon: 'pi pi-wave-pulse'})
}
if (authStore.canSeePulse) {
  tabs.push({label: 'hOS Pulse', to: '/pulse/hos', icon: 'pi pi-calendar'})
}
</script>

<template>
  <AppPage title="Pulse" :loaded="true" :tabs="tabs">
    <RouteTab tabRoute="/pulse">
      <PulseWeeklyView :user-id="authStore.user.id" />
    </RouteTab>
    <RouteTab tabRoute="/pulse/hos">
      <CompanyPulse />
    </RouteTab>

  </AppPage>
</template>
