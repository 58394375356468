<script setup>
import PulseCard from "@/components/pulse/PulseCard.vue";
import {computed} from "vue";
import {DateTime} from "luxon";

const props = defineProps(['pulses', 'editable', 'showWeekendButton'])
const pulses = computed(() => props.pulses ? Object.values(props.pulses) : [])

function addWeekend() {
  console.log("Adding weekend")
  const maxDate = Object.keys(props.pulses)[pulses.value.length - 1]
  const nextDate = DateTime.fromISO(maxDate).plus({days: 1}).toISODate()
  props.pulses[nextDate] = {date: nextDate, type: 'PULSE'}
}

</script>

<template>
  <div class="grid xl:grid-cols-4 gap-2 justify-center">
    <PulseCard class="col-span-2"
               v-for="pulse in pulses"
               :pulse="pulse" :key="pulse.date" :editable="editable"/>
    <div class="w-ful flex items-center justify-center">
      <Button v-if="editable && showWeekendButton" @click="addWeekend">I worked this weekend</Button>
    </div>
  </div>
</template>
