<script setup>
import {ref, defineProps, defineEmits} from 'vue';

import InputGroup from "primevue/inputgroup";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

const {modelValue} = defineProps({
  modelValue: String
});
const emit = defineEmits(['update:modelValue']);

const localValue = ref(modelValue);
const edit = ref(false)


function confirmEdit() {
  edit.value = false;
  emit('update:modelValue', localValue.value);
}

function cancelEdit() {
  edit.value = false;
  localValue.value = modelValue;
}

</script>

<template>
  <span v-if="!edit" @click="edit = true">{{ modelValue }}</span>
  <InputGroup v-else>
    <InputText v-model="localValue" size="small" :disabled="!edit"/>
    <Button icon="pi pi-check" severity="secondary" @click="confirmEdit"/>
    <Button icon="pi pi-times" severity="secondary" @click="cancelEdit"/>
  </InputGroup>


</template>
