class Toaster {
    toast;
    constructor(toast) {
        this.toast = toast
    }

    message(severity, summary, detail) {
        this.toast.add({severity: severity, summary: summary, detail: detail, life: 3000})
    }
    success(summary, detail) {
        this.message('success', summary, detail)
    }

    error(message) {
        this.message('error', 'Error', message)
    }

    warning(message) {
        this.message('warning', 'Warning', message)
    }
}

export {Toaster};