<script setup lang="ts">

import TimeOffCard from "@/components/timeoff/TimeOffCard.vue";
import TimeOffList from "@/components/timeoff/TimeOffList.vue";
import Button from "primevue/button";
import Popover from "primevue/popover";
import ConfirmPopup from "primevue/confirmpopup";
import {onMounted, ref} from "vue";
import {timeoffBackend, TimeoffDTO} from "@/backend/timeoff.backend.ts";
import useAppToast from "@/composables/useAppToast";
import { useConfirm } from "primevue/useconfirm";

const toaster = useAppToast();
const confirm = useConfirm();


const requestTimeoffPanel = ref();
const timeoffList = ref<TimeoffDTO[] | null>(null);
const selected = ref<TimeoffDTO | null>(null);

function hidePanel() {
  requestTimeoffPanel.value.hide();
}

async function refresh() {
  timeoffList.value = await timeoffBackend.getTimeoff();
}

onMounted(async () => {
  await refresh();
});

function confirmDeleteTimeoff(event: any, timeoff: TimeoffDTO) {
  confirm.require({
    target: event.currentTarget,
    message: 'Are you sure?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-sm p-button-danger',
    rejectLabel: 'Cancel',
    acceptLabel: 'Yes, delete it',
    accept: async () => {
      await deleteTimeoff(timeoff);
    },
    reject: () => {
      toaster.add({ severity: 'error', summary: 'Canceled', detail: 'You have canceled', life: 3000 });
    }
  });
}

async function deleteTimeoff(timeoff: TimeoffDTO) {
  try {
    await timeoffBackend.deleteTimeoff(timeoff);
    toaster.success('Time off deleted');
    await refresh();
  } catch (e: any) {
    toaster.error(e.message);
  }
}

async function saveTimeOff(timeOff: TimeoffDTO) {
  try {

    const saved = await timeoffBackend.saveTimeoff(timeOff);
    toaster.success('Time off saved');
    requestTimeoffPanel.value.hide();
    await refresh();
    selected.value = saved;
  } catch (e: any) {
    toaster.error(e.message);
  }
}

const requestTimeOff = (event: any) => {
  requestTimeoffPanel.value.show(event);
};
</script>

<template>
  <div class="flex gap-2">
    <TimeOffList :timeoffList="timeoffList" :hide-name="true" class="grow">
      <template #toolbar="slotProps">
        <Button @click="requestTimeOff" icon="pi pi-calendar-plus" outlined label="Request"/>
        <Popover ref="requestTimeoffPanel">
          <TimeOffCard @save="saveTimeOff" @close="hidePanel" :existing="slotProps.data"/>
        </Popover>
      </template>
      <template #timeoffActions="slotProps">
        <Button icon="pi pi-trash" text severity="secondary"
                @click="e => confirmDeleteTimeoff(e, slotProps.data)"/>
      </template>
      <template #empty>
        <h3>You haven't requested any time off yet</h3>
      </template>
    </TimeOffList>
    <ConfirmPopup/>

  </div>
</template>
