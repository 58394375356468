<script>
import {timeoffHelper as to} from "@/helpers/timeoff.helper";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TimeoffType from "@/components/timeoff/TimeoffType.vue";

export default {
  props: ['entries', 'year'],
  components: {DataTable, Column, TimeoffType},
  data: () => ({
  }),
  computed: {
    yearStats() {
      return to.types().map(type => {
        return {'label': type.label, 'color': type.color, 'count': to.countAllWorkdays(this.entries, type.value)}
      }).filter(stat => stat.count > 0);
    },

    countAllWorkdays() {
      return to.countAllWorkdays(this.entries);
    }

  }
}
</script>
<template>
  <div>
    <h3>Timeoff in {{ year }} </h3>
    <DataTable :value="yearStats">
      <Column field="label" header="Total">
        <template #body="slotProps">
          <TimeoffType :label="slotProps.data.label" :color="slotProps.data.color"/>
        </template>
      </Column>
      <Column field="count" :header="countAllWorkdays">

      </Column>
    </DataTable>
  </div>
</template>
