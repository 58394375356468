<script>
  import AppPage from "@/components/AppPage.vue";
  import Button from "primevue/button";

  export default {
    components: {AppPage, Button},
    emits: ['consent']
  }
</script>
<template>
  <div title="Before you dive in" class="flex flex-col justify-center">

      <h1>Before you dive in</h1>

    <div class="flex flex-col justify-center">
    <p>Here are some hints on how to process the feedback</p>

    <div >
      <p><strong>Analyze results</strong></p>
      <p>
        As you’ve created the form yourself, only you can see the results. As a form of accountability we encourage you
        to
        talk with a trusted confidant about your results but only after you have had time to review and digest
        everything
        yourself.
      </p>
    </div>
    <div class="alert alert-info">
      <p><strong>Suggestions when reviewing your feedback</strong></p>
      <div class="text-start">
        <ul>
          <li>React, then respond</li>
          <li>
            Resist the urge to “witch hunt” or become angry/resentful towards those that shared their feedback
          </li>
          <li>
            Remember this feedback is not meant to attack or tear you down but to help you improve as an individual
            across
            numerous dimensions of your life
          </li>
          <li>
            There is your reality vs. the perception that others have of you. Understand that this feedback from
            individuals is
            just some peoples perspective.
          </li>
          <li>
            If you need to talk with a team member or your manager do not hesitate to reach out. The purpose of this
            exercise is
            to gain insight and create the next version of you.
          </li>
        </ul>
      </div>
    </div>
    <Button label="Got it" @click="this.$emit('consent', true);"/>
    </div>
  </div>
</template>