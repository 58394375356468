<script setup lang="ts">
import Button from "primevue/button";

type Props = {
  label?: string
  severity?: string
  size? : string
}
const model = defineModel()
const emit = defineEmits()
defineProps<Props>()

function toggle() {
  emit('update:modelValue', !model.value)
}
</script>

<template>
  <Button :label="label" :severity="severity" :outlined="!model" @click="toggle" :size="size"/>
</template>
