<script setup lang="ts">
import {RouterView} from 'vue-router'
import {useAuthStore} from "@/stores";
import Toast from 'primevue/toast';
import {onErrorCaptured, onMounted, ref} from "vue";
import {initDark} from "@/composables/useDark"

import useAppToast from "@/composables/useAppToast";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import SignIn from "@/components/SignIn.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";

const toast = useAppToast()

onErrorCaptured((error) => {
  toast.error(error)
})
const authStore = useAuthStore();

const search = ref();

function showSearchOnCmdK(event: KeyboardEvent) {
  if (event.key === 'k' && event.metaKey) {
    event.preventDefault();
    search.value.show();
  }


}
onMounted(() => {
  initDark();
  useAuthStore().refreshToken();
  window.addEventListener('keydown', showSearchOnCmdK)
})

</script>

<template>
  <Toast/>
  <DefaultLayout>
    <div v-if="authStore.jwt" class="flex justify-center">
      <GlobalSearch ref="search" />
      <RouterView v-if="authStore.user"/>
    </div>
    <div v-else class="flex flex-col justify-start h-full pt-12">
      <SignIn />
    </div>
  </DefaultLayout>
</template>
