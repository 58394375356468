<script setup lang="ts">

import CheckinCard from "@/components/CheckinCard.vue";
import Menu from "primevue/menu";
import Button from "primevue/button";
import {CheckinHelper} from "@/helpers";
import {ref} from "vue";

const {entry} = defineProps(['entry', 'editing'])

const skipReasons = CheckinHelper.skipReasons();
const skipItems = [{
  label: 'Reason to skip',
  items: [
    {label: skipReasons['ooo'], command: () => toggleSkip('ooo')},
    {label: skipReasons['no1to1'], command: () => toggleSkip('no1to1')}
  ]
}

];

function toggleSkip(skipReason: string | null) {

  if (skipReason) {
    entry.skipReason = skipReason;
    entry.skip = true;
    entry.happiness = 0;
    entry.productivity = 0;
    entry.passion = 0;
    entry.comment = "";
  } else {
    entry.skip = false;
    entry.skipReason = null;
  }
}

const menu = ref();
function toggleSkipMenu(event: any) {
  menu.value.toggle(event);
}

</script>
<template>
  <div>
    <div class="flex justify-end items-start">
      <CheckinCard :entry="entry" class="w-full" :readonly="!editing">
        <template #left>
          <Button :label="`Skip ${entry.person.name?.split(' ')[0]}`" severity="secondary" outlined size="small"
                  @click="toggleSkipMenu"
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                  v-if="editing"/>
          <Menu ref="menu" id="overlay_menu" :model="skipItems" :popup="true"/>
        </template>
        <template #skip>
          <div>

          <Button aria-label="Cancel" icon="pi pi-times" severity="secondary" size="small" label="Unskip" outlined
                  @click="toggleSkip(null)" v-if="editing"/>
          </div>
        </template>
      </CheckinCard>
    </div>
  </div>

</template>
