import {useToast} from "primevue/usetoast";


export default () => {
    const toast = useToast()

    function add_toast(severity, summary, detail) {
        toast.add({severity: severity, summary: summary, detail: detail, life: 3000})
    }

    return {
        message(severity, summary, detail) {
            add_toast(severity, summary, detail)
        },
        success(summary, detail) {
            add_toast('success', summary, detail)
        },

        error(message) {
            add_toast('error', 'Error', message)
        },

        warning(message) {
            add_toast('warning', 'Warning', message)
        },

        add(opts) {
            toast.add(opts)
        }
    }
}

