<script setup lang="ts">
import {computed} from "vue";
import {useRoute} from "vue-router";
import Skeleton from "primevue/skeleton";

const route = useRoute();

const {tabRoute} = defineProps(['tabRoute'])
const visible = computed(() => {
  return route.path === tabRoute
})
</script>

<template>
  <Suspense  v-if="visible">
    <slot/>
    <template #fallback>
      <Skeleton />
    </template>
  </Suspense>
</template>
