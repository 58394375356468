<script setup lang="ts">
import Dimension from "./Dimension.vue";
import Textarea from "primevue/textarea";
import {CheckinHelper} from "@/helpers";
import SmartText from "@/components/SmartText.vue";
import {computed} from "vue";
import {DateTime} from "luxon";
import Comments from "@/components/checkin/Comments.vue";
import {firstName} from "@/helpers/utils.ts";
import {checkinBackend} from "@/backend/checkin.backend.ts";

type CheckinCardProps = {
  entry: any;
  self?: boolean;
  readonly?: boolean;
  fontSize?: number;
}

const {entry, self, readonly, fontSize} = defineProps<CheckinCardProps>()
const createdAt = computed(() => humanDateTime(entry.createdAt));
function humanDateTime(date: string) {
  return DateTime.fromISO(date).toFormat("MMM dd, yyyy HH:mm");
}


const personFirstName = computed(() => {
  return firstName(entry!.person.name);
})

function getSkipReason() {
  // @ts-ignore
  return CheckinHelper.skipReasons()[entry.skipReason];
}

async function refreshComments() {
  entry.comments = await checkinBackend.refreshComments(entry.id)();

}
</script>

<template>
  <div>
    <div class="flex flex-col md:flex-row gap-4 w-full" v-if="!entry.skip">
      <div class="flex flex-col justify-start gap-4 min-w-[14rem]">
        <Dimension v-model="entry.happiness" dimension="happiness"
                   :self="self" :readonly="readonly" :key="`${entry.happiness}-${readonly}`"
                   class="!items-end"/>
        <Dimension v-model="entry.productivity" dimension="productivity"
                   :self="self" :readonly="readonly" :key="`${entry.productivity}-${readonly}`"
                   class="!items-end"/>
        <Dimension v-model="entry.passion" dimension="passion"
                   :self="self" :readonly="readonly" :key="`${entry.passion}-${readonly}`"
                   class="!items-end "/>
        <Tag icon="pi pi-check-circle" severity="secondary" value="Acknowledged" rounded
             v-if="entry.acknowledgedById"/>
        <slot name="left"/>
      </div>
      <div class="grow flex justify-stretch">
        <Textarea v-model="entry.comment" rows="10" class="w-full h-full"
                autoResize
                :placeholder="`Please provide 2-4 sentences on how ${ self ? 'you are' : personFirstName + ' is'} doing this week.`"
                v-if="!readonly"/>
        <div v-else class="flex flex-col grow">
          <SmartText :text="entry.comment" :style="`font-size: ${fontSize || 1.1}rem`" class="border-l grow p-2 pl-4"/>
          <div class="gap-2 flex flex-wrap justify-end items-center">
            <span class="text-muted-color text-sm">{{ createdAt }}</span>
          </div>
          <div>
            <Comments :commentable="entry" :key="entry.comments?.length" class="mt-2" @refresh="refreshComments"/>
          </div>
        </div>
      </div>

    </div>

    <div v-if="entry.skip" class="flex flex-col items-left gap-2">
        <slot name="skip"/>
        <span class="text-muted-color">Skipped: {{ getSkipReason() }}</span>
    </div>
  </div>

</template>


