<script>
import {backend, CheckinHelper, Toaster} from "@/helpers";
import AppPage from "@/components/AppPage.vue";
import Accordion from "primevue/accordion";
import Button from "primevue/button";
import LeadCheckinCard from "@/components/LeadCheckinCard.vue";
import hOSAvatar from "@/components/hOSAvatar.vue";
import CheckinCard from "@/components/CheckinCard.vue";
import Weekdays from "@/components/Weekdays.vue";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import Select from "primevue/select";
import PulseWeekToggle from "@/components/pulse/PulseWeekToggle.vue";
import PulseWeek from "@/components/pulse/PulseWeek.vue";
import Skeleton from "primevue/skeleton";
import BlockUI from "primevue/blockui";
import Debug from "@/components/Debug.vue";
import RetroWeekPicker from "@/components/retro/RetroWeekPicker.vue";
import {todayWeek} from "@/composables/useRetroWeek";

export default {
  components: {
    RetroWeekPicker,
    Debug,
    PulseWeek,
    PulseWeekToggle,
    Weekdays,
    CheckinCard, LeadCheckinCard, AppPage, Accordion, Button, hOSAvatar, Select, Panel,
    ProgressBar,
    Skeleton,
    BlockUI

  },
  data: () => ({
    checkin: null,
    todayWeek: null,
    selectedWeek: null,
    successMsg: "",
    errorMsg: "",
    saving: false,
    editing: false,
    changed: false,
    loaded: false,
    intervalId: null
  }),
  watch: {
    checkin: {
      handler() {
        this.changed = true;
      },
      deep: true
    }
  },
  async mounted() {
    this.todayWeek = await todayWeek();
    this.selectedWeek = this.todayWeek;
    await this.init();
  },
  computed: {
    progress() {
      return this.countFilled / this.checkin.checkins.length * 100;
    },
    countFilled() {
      return CheckinHelper.countFilled(this.checkin.checkins);
    },
    filled() {
      return CheckinHelper.isCheckinFilled(this.checkin.checkins);
    },
    toaster() {
      return new Toaster(this.$toast);
    },

    editable() {
      return this.selectedWeek == this.todayWeek;
    }
  },
  methods: {
    async init() {
      this.loaded = false;
      this.checkin = await backend.getLeadCheckin(this.selectedWeek.toISODate());
      if (!this.checkin.id && this.checkin.checkins) { // If the checkin is not submitted yet, and it was not missed (past weeks)
        this.loadLocalCheckin();
        this.intervalId = setInterval(this.updateStorage, 5000);
        this.editing = true;
      } else {
        this.editing = false;
        // If the checkin is already submitted, we don't need to save it locally
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
      this.loaded = true;

    },
    checkinClass(entry) {
      if (entry.skip) {
        return "pi-times-circle";
      }
      if (CheckinHelper.hasAllData(entry)) {
        return "pi-check";
      }
      if (CheckinHelper.hasSomeData(entry)) {
        return "pi-hourglass";
      }
      return "pi-circle";
    },

    loadLocalCheckin() {
      let checkinJSON = localStorage.checkin;
      if (checkinJSON) {
        try {
          const localCheckin = JSON.parse(checkinJSON);
          this.checkin.checkins.forEach(c => {
            const localEntry = localCheckin[c.person.id];
            if (localEntry) {
              c.happiness = localEntry.happiness;
              c.productivity = localEntry.productivity;
              c.passion = localEntry.passion;
              c.comment = localEntry.comment;
              c.skip = localEntry.skip;
              c.skipReason = localEntry.skipReason;
            }
          });

        } catch (e) {
          console.log("Welp, it didn't work", e);
          localStorage.checkin = "";
        }

      }

    },

    updateStorage() {
      if (this.changed) {
        const localCheckins = {};
        this.checkin.checkins.forEach(c => {
          localCheckins[c.person.id] = c;
        });
        localStorage.checkin = JSON.stringify(localCheckins);
        this.changed = false;
      }
    },
    clearStorage() {
      localStorage.checkin = "";
    },
    async saveCheckin() {
      try {
        this.saving = true;
        this.editing = false;
        await backend.saveLeadCheckin(this.checkin);
        await this.init();
        this.clearStorage();
        console.log("Saved the checkin");
        this.toaster.success("Saved!", "Thanks for your checkin!");
      } catch (e) {
        console.log("Caught error", e);
        this.toaster.error("Oops :(", "Couldn't save your checkin");
      }
      this.saving = false;
    },
    async cancelEdit() {
      this.editing = false;
      await this.init();
    }
  }
}


</script>

<template>
  <div class="flex items-center justify-center" v-if="!!checkin">
    <div class="w-full xl:w-[60rem] self-center">
      <div class="flex mb-4">
        <RetroWeekPicker v-model="selectedWeek" @change="init" />
        <Button v-if="editable && !editing" @click="editing = true" label="Edit" icon="pi pi-pencil" class="ml-2"/>
      </div>
      <BlockUI :blocked="!loaded">
        <ProgressBar
            v-if="editing && checkin.checkins.length > 1"
            class="!sticky top-0 z-40 mb-2"
            :value="progress">
          <div v-if="!filled">
            {{ countFilled }} / {{ checkin.checkins.length }}
          </div>
          <div v-else>
            All set!
          </div>

        </ProgressBar>
        <div v-if="checkin.checkins">
          <div>
            <Panel v-for="entry in checkin.checkins" :key="`${entry.person.id}-${entry.id}`" class="mb-4">
              <template #header>
                <div class="flex flex-col md:flex-row justify-between w-full">

                  <div class="flex items-center gap-2">
                    <i class="pi" :class="checkinClass(entry)" style="font-size: 1.5rem"/>
                    <hOSAvatar :user="entry.person" size="medium"/>
                    <span class="font-bold whitespace-nowrap text-2xl">  {{ entry.person.name }}</span>
                  </div>
                  <div class="flex">
                    <Weekdays :weekdays="entry.weekdays"/>
                    <PulseWeekToggle :selector="`pulse-${entry.person.id}`" :enabled="entry.person.pulse_enabled"/>
                  </div>
                </div>
              </template>

              <PulseWeek :id="`pulse-${entry.person.id}`" :pulses="entry.weekdays" class="hidden mb-8"/>
              <LeadCheckinCard :entry="entry" :editing="editing"/>
            </Panel>
            <!--        </Accordion>-->
            <form @submit.prevent="saveCheckin" v-if="editing">
              <div class="flex justify-end gap-2">
                <Button severity="secondary" @click="cancelEdit" v-if="checkin.id">Cancel</Button>
                <Button type="submit" @submit.prevent :disabled="saving || !filled">Save</Button>
              </div>
              <span v-if="!filled" class="form-label px-4 p-text-secondary">Please fill info for all people before you submit the checkin</span>
            </form>
          </div>

        </div>
        <div v-else-if="loaded">
          <h3>You didn't submit a checkin for this week.</h3>
        </div>
        <Debug :data="checkin" />
      </BlockUI>
    </div>
  </div>
  <div v-else>
    <Skeleton />
  </div>
</template>

<style>
</style>
