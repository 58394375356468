import {request} from "@/backend/backend_util.ts";
import {DateTime} from "luxon";

const retroBackend = {
    getCurrentWeek: async () => {
        const resp = await request<any>('GET', '/retros/current-week')();
        return DateTime.fromISO(resp.monday);
    }
}

export default retroBackend;