<script setup lang="ts">

import useAppUser from "@/composables/useAppUser.ts";
import AppPage from "@/components/AppPage.vue";
import {profileBackend, ProfileResponseType} from "@/backend/profile.backend";
import useAppToast from "@/composables/useAppToast";
import {useRoute} from "vue-router";
import ProfileViewer from "@/components/ProfileViewer.vue";
import {computed, onMounted, ref, watch} from "vue";
import Debug from "@/components/Debug.vue";
import UserDetails from "@/components/user/UserDetails.vue";
import Skeleton from "primevue/skeleton";
import {firstName} from "@/helpers/utils.ts";

const route = useRoute();
const loggedUser: any = useAppUser();
if (!loggedUser)
  throw new Error("User not logged in");
const username = ref<string>(route.params.username || loggedUser.username);
const profile = ref<ProfileResponseType>();
const user = computed(() => profile.value?.user);

function init() {
  const user = profile.value?.user;
  if (user && user.meta?.startDate) {
    user.meta.startDate = new Date(user.meta.startDate);
  }
}

async function loadData(newUsername: string) {
  username.value = newUsername || loggedUser.username;
  profile.value = await profileBackend.get(username.value);
  init();
}
onMounted(async () => {
  await loadData(route.params.username as string);
});

// watch route changes
watch(() => route.params.username, async (newUsername) => {
  await loadData(newUsername as string);
});
const self = computed(() => loggedUser!.username == username.value);
const editable = computed(() => self.value || loggedUser!.is_admin);
const editing = ref(false);

const toaster = useAppToast();

const loaded = computed(() => profile.value !== undefined);

const title = computed(() => {
  if (user.value) {
    return self.value ? "My Profile" : `${firstName(user.value.name)}'s Profile`;
  }
  return "Profile";
});

async function update() {
  try {
    profile.value = await profileBackend.update(user.value);
    init();
    toaster.success("Changes saved");
  } catch (e) {
    toaster.error(e);
  }
}
</script>

<template>


  <AppPage :title="title" :loaded="loaded">
    <Suspense>
      <UserDetails :user="user" @update:user="update" v-if="editing">
        <template #actions>
          <Button v-if="editable && editing" @click="editing = false" label="Back" icon="pi pi-arrow-left" severity="secondary" size="small"/>
        </template>
      </UserDetails>
      <ProfileViewer v-else :profile="profile" >
        <template #actions>
          <Button v-if="editable && !editing" @click="editing = true" label="Edit" icon="pi pi-pencil" class="ml-2" size="small"/>
        </template>
      </ProfileViewer>
      <template #fallback>
        <Skeleton />
      </template>
    </Suspense>
    <Debug :data="{username, user, self, editable, editing, loggedUser}"/>
  </AppPage>
</template>
