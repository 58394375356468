<script>
import Card from "primevue/card";
import Panel from "primevue/panel";

export default {
  props: ['sksPost', 'name', 'header'],
  components: {Card, Panel},

}

</script>

<template>
  <Panel>
    <template #header>
      <strong>{{ header }}</strong>
    </template>
    <template #footer>
      <div class="flex justify-end" v-if="name">
        –– {{ name }}
      </div>
    </template>
    <p style="white-space: pre-wrap">
      {{ sksPost }}
    </p>
  </Panel>
</template>