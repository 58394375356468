<script setup lang="ts">
import Avatar from "primevue/avatar";
import {backend} from "@/backend/backend.js";
import {computed, ref} from "vue";

const {user} = defineProps(['user', 'size']);

const hasImage = ref(true)

const initials = computed(() => {
  return hasImage.value ? null : user.name.split(" ").map((n: string) => n[0]).join("");
});


function onNoImage() {
  hasImage.value = false;
}

</script>


<template>
  <Avatar :image="backend.avatar_url(user.id)" :size="size" :label="initials" shape="circle" :key="user.id"
          @error="onNoImage" class="!bg-surface-200 dark:!bg-surface-700"/>
</template>

