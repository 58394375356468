import {ref} from "vue";

export const isDark = () => document.querySelector('html')?.classList.contains('dark') || false

export function toggleDark() {
    const html = document.querySelector('html')
    html?.classList.toggle('dark')
    localStorage.setItem('dark', isDark() ? 'true' : 'false')
    darkKey.value++
}

export function initDark() {
    const dark = localStorage.getItem('dark')
    if (dark === 'true') {
        toggleDark()
    }
}

export const darkKey = ref(0)
