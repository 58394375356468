<script setup>
import {computed} from "vue";

const model = defineModel()
const {readonly, dimension, self} = defineProps(['readonly', 'dimension', 'self'])

const data = computed(() => {
  return dimensionData.value[dimension].options;
})

const circleClass = computed(() => {
  return {
  'text-primary-300': model.value === 0,
  'text-red-300': model.value === 1,
  'text-yellow-300': model.value === 2,
  'text-blue-300': model.value === 3,
  'text-green-300': model.value === 4,
  'text-green-500': model.value === 5,
  'cursor-pointer': !readonly
  }
})
const color = computed(() => {
  switch (model.value) {
    case 1: return 'text-red-300';
    case 2: return 'text-yellow-300';
    case 3: return 'text-blue-300'
    case 4: return 'text-green-300'
    case 5: return 'text-green-500'
    default: return 'text-primary-300';
  }
})

const dimensionData = computed(() => {
  const pronoun = self ? 'I' : 'they'
  const determiner = self ? 'my' : 'their'
  return {
  'happiness': {
    label: 'Happiness',
    options: [
      'Not at all happy',
      'Somewhat happy',
      'Expected level of happiness',
      'Very happy',
      'Couldn\'t be happier right now'
    ]
  },
  'productivity': {
    label: 'Productivity',
    options: [
      'Did not complete any of the expected work this week',
      'Completed some of the expected work this week',
      'Completed the expected amount of work',
      'More than the expected amount of work',
      'Went above and beyond with the completed level of work'
    ]
  },
  'passion': {
    label: 'Passion Alignment',
    options: [
      `Little or no passion in ${determiner} work`,
      `Some passion in ${determiner} work`,
      `Normal amount of passion in ${determiner} work`,
      `More than the expected amount of passion in ${determiner} work`,
      `Above and beyond passionate about what ${pronoun} have going on in ${determiner} work`
    ]
  }
  }
})

function update(i) {
  if (!readonly)
    model.value = i + 1;
}
</script>
<template>
  <div class="flex flex-col md:items-center gap-2">
      <strong> {{ dimensionData[dimension].label }}</strong>
    <div class="flex flex-row gap-1">

      <span v-for="(d, i) in data" :class="circleClass" @click="update(i)" v-tooltip.bottom="{value: d, showDelay: 500}" :key="d">
        <i class="pi" :class="model-1 >= i ? 'pi-circle-fill' : 'pi-circle'" style="font-size: 1.3rem"/>
      </span>
    </div>
  </div>
</template>
